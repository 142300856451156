import { render, staticRenderFns } from "./UserBox.vue?vue&type=template&id=09f41e9e&scoped=true"
import script from "./UserBox.vue?vue&type=script&lang=js"
export * from "./UserBox.vue?vue&type=script&lang=js"
import style0 from "./UserBox.vue?vue&type=style&index=0&id=09f41e9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f41e9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VHover,VIcon})
