<template>
  <div>
    <v-container>
      <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />

      <div
        v-if="user && $route.params.id != -1"
        style="display: inline-block; font-size: 12px; vertical-align: middle"
      >
        <div>
          <span style="min-width: 125px; display: inline-block">{{
            $t("last_connect")
          }}</span
          >{{ user.lastSignInTime }}
        </div>
        <div>
          <span style="min-width: 125px; display: inline-block">{{
            $t("created_at")
          }}</span
          >{{ user.creationTime }}
        </div>
      </div>
      <v-dialog
        v-model="deleteUserDialog"
        v-if="user && $route.params.id != -1"
        max-width="400"
      >
        <template v-if="isAdmin" v-slot:activator="{ on, attrs }">
          <v-btn fab dark style="float: right" v-bind="attrs" v-on="on">
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("confirm_delete") }}
          </v-card-title>
          <v-card-text> {{ $t("confirm_delete_user") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteUserDialog = false">{{ $t("no") }}</v-btn>
            <v-btn text @click="deleteUser"> {{ $t("yes") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        fab
        dark
        style="float: right; margin-right: 10px"
        @click="$router.push(`/remote/${$route.params.id}/${user.email}`)"
      >
        <v-icon>mdi-code-tags </v-icon>
      </v-btn>
      <div v-if="user" class="mt-10">
        <div style="max-width: 800px; margin: auto">
          <v-row>
            <v-col cols="6">
              <h3>{{ $t("infos") }}</h3>
            </v-col>
            <v-col cols="6" align="right" class="mb-3">
              <v-btn
                rounded
                elevation="0"
                class="no-transform"
                @click="cancel"
                v-if="changes || $route.params.id == -1"
              >
                {{ $t("cancel") }}
              </v-btn>

              <v-btn
                :disabled="!valid || !changes"
                :loading="saving"
                color="primary"
                class="ml-4 no-transform"
                rounded
                @click="save"
              >
                <v-icon class="mr-5">mdi-content-save-edit-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          style="max-width: 800px; margin: auto"
        >
          <v-card style="max-width: 800px; margin: auto">
            <v-card-subtitle style="padding-bottom: 4px">
              <div class="info-container-no-main">
                <div class="info-icon">
                  <v-icon class="mr-10">mdi-account-outline</v-icon>
                </div>
                <div class="info-desc">
                  <p style="display: inline">{{ $t("username") }}</p>
                </div>
                <div class="info-right">
                  <v-text-field
                    style="width: 320px"
                    v-model="user.displayName"
                    outlined
                    dense
                    @change="displayNameChanged"
                    validate-on-blur
                  ></v-text-field>
                </div>
              </div>
            </v-card-subtitle>
          </v-card>
          <div style="height: 25px"></div>

          <v-card style="max-width: 800px; margin: auto">
            <v-card-subtitle style="padding-bottom: 4px">
              <div class="info-container-no-main">
                <div class="info-icon">
                  <v-icon class="mr-10">mdi-email-outline</v-icon>
                </div>
                <div class="info-desc">
                  <p style="display: inline">{{ $t("signup.email") }}</p>
                </div>
                <div class="info-right">
                  <v-text-field
                    style="width: 320px"
                    v-model="user.email"
                    :rules="emailRules"
                    outlined
                    dense
                    required
                    validate-on-blur
                    @change="emailChanged"
                  ></v-text-field>
                </div>
              </div>
            </v-card-subtitle>
          </v-card>

          <div style="height: 25px"></div>

          <v-card style="max-width: 800px; margin: auto">
            <v-card-subtitle style="padding-bottom: 4px">
              <div class="info-container-no-main">
                <div class="info-icon">
                  <v-icon class="mr-10">mdi-account-key-outline</v-icon>
                </div>
                <div class="info-desc">
                  <p style="display: inline">{{ $t("role") }}</p>
                </div>
                <div class="info-right">
                  <v-select
                    :items="roles"
                    style="width: 320px"
                    outlined
                    dense
                    v-model="user.singleRole"
                    item-text="name"
                    item-value="value"
                    validate-on-blur
                    @change="roleChanged"
                  ></v-select>
                </div>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-form>

        <div style="height: 25px"></div>
        <div style="max-width: 800px; margin: auto">
          <h3>{{ $t("additional_infos") }}</h3>
        </div>

        <div style="height: 25px"></div>
        <v-card style="max-width: 800px; padding: 24px; margin: auto">
          <v-row>
            <v-col cols="6"> </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="infos.company"
                :rules="requiredRules"
                :label="$t('signup.company')"
                required
                validate-on-blur
                style="margin: 0px 0px"
                @change="companyChanged"
              ></v-text-field>

              <v-text-field
                v-model="infos.phone"
                :rules="[
                  (v) => !!v || $t('signup.required'),
                  (v) =>
                    /(\(\d{3}\) \d{3}-\d{4})/.test(v) ||
                    $t('signup.phone_must'),
                ]"
                :label="$t('signup.phone')"
                required
                v-mask="'(###) ###-####'"
                validate-on-blur
                style="margin: 0px 0px"
                @change="phoneChanged"
              ></v-text-field>

              <!-- Country Selection -->
              <v-autocomplete
                v-model="infos.country"
                :items="countryNames"
                :rules="requiredRules"
                :label="$t('signup.country')"
                item-text="name"
                item-value="code2"
                return-object
                @change="onCountryChange"
                autocomplete="on"
              ></v-autocomplete>

              <v-textarea
                v-model="infos.address"
                :rules="requiredRules"
                rows="2"
                :label="$t('signup.address')"
                required
                validate-on-blur
                style="margin: 0px 0px"
                @change="addressChanged"
              ></v-textarea>

              <v-text-field
                v-model="infos.city"
                :rules="requiredRules"
                :label="$t('signup.town')"
                required
                validate-on-blur
                style="margin: 0px 0px"
                @change="cityChanged"
              ></v-text-field>

              <v-text-field
                v-model="infos.postalcode"
                :rules="requiredRules"
                :label="$t('signup.postalcode')"
                required
                validate-on-blur
                @change="postalCodeChanged"
                style="margin: 0px 0px"
              ></v-text-field>

              <v-autocomplete
                v-model="infos.state"
                :items="stateNames"
                :rules="requiredRules"
                :label="$t('signup.province')"
                item-text="name"
                item-value="code"
                return-object
                :disabled="!infos.country"
                autocomplete="on"
                @change="stateChanged"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card>

        <br />

        <div
          style="max-width: 800px; margin: auto"
          v-if="$route.params.id != -1"
        >
          <h3>{{ $t("garage") }}</h3>
        </div>

        <br />

        <v-card
          style="max-width: 800px; margin: auto"
          v-if="$route.params.id != -1"
        >
          <v-container style="padding: 12px">
            <div v-if="user.cars && user.cars.length > 0">
              <div v-for="(car, index) in user.cars" :key="index">
                <p>
                  {{
                    (car.nickname ? car.nickname + " / " : "") +
                    (car.serial ? car.serial + " / " : "") +
                    (car.brand ? car.brand + " " : "") +
                    (car.model ? car.model + " " : "") +
                    (car.year ? car.year : "")
                  }}
                </p>
                <v-divider />
                <br />
                <v-row class="mb-3">
                  <v-col
                    v-for="(link, index) in car.links"
                    :key="index"
                    sm="6"
                    cols="12"
                  >
                    <LinkBox :link="link" />
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-else style="text-align: center; font-style: italic">
              {{ $t("no_link") }}
            </div>
          </v-container>
        </v-card>
      </div>
      <v-skeleton-loader
        v-else
        type="article, actions"
        style="margin: 15px"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import LinkBox from "../components/LinkBox.vue";
import { parseError, formatUser } from "../utils";

import { mapMutations, mapGetters } from "vuex";

import countries from "./countries.json";

export default {
  name: "User",
  components: { Breadcrumbs, LinkBox },
  computed: {
    ...mapGetters(["isAdmin", "isTech"]),
    countryNames() {
      return this.countries.map((country) => ({
        name: country.name,
        code2: country.code2,
        states: country.states,
      }));
    },
    stateNames() {
      return this.infos.country ? this.infos.country.states : [];
    },
  },
  data: () => {
    return {
      user: null,
      breadcrumbs: [],
      changes: null,
      valid: true,
      saving: false,
      emailRules: [],
      roles: [],
      deleteUserDialog: false,
      requiredRules: [(v) => !!v || "Required."],
      infos: {
        company: "",
        phone: "",
        address: "",
        city: "",
        country: null,
        state: null,
        postalcode: "",
      },
      countries: countries,
    };
  },
  methods: {
    resetChanges() {
      this.changes = {
        infos: {
          company: "",
          phone: "",
          country: null,
          address: "",
          city: "",
          state: null,
          postalcode: "",
        },
      };
    },
    onCountryChange() {
      this.infos.state = null; // Reset the state selection when the country changes
      this.countryChanged();
    },
    ...mapMutations({
      setSelectedUser: "setSelectedUser",
    }),
    companyChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.infos.company = this.infos.company;
    },
    phoneChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.infos.phone = this.infos.phone;
    },
    countryChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.infos.country = this.infos.country.name;
    },
    addressChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.infos.address = this.infos.address;
    },
    cityChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.infos.city = this.infos.city;
    },
    postalCodeChanged() {
      if (!this.changes) {
        this.resetChanges();
      }

      this.changes.infos.postalcode = this.infos.postalcode;
    },
    stateChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.infos.state = this.infos.state.name;
    },

    emailChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.email = this.user.email;
    },
    displayNameChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.displayName = this.user.displayName;
    },
    roleChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.role = this.user.singleRole;
    },
    approvedChanged() {
      if (!this.changes) {
        this.resetChanges();
      }
      this.changes.approved = this.user.approved;
    },
    async save() {
      try {
        this.saving = true;

        let changes = {};
        if (this.$route.params.id == -1) {
          // Si on créé un usager, on sauvegarde tous les champs
          changes.email = this.user.email;
          changes.displayName = this.user.displayName;
          changes.role = this.user.singleRole;
          changes.infos = this.changes.infos;
          // changes.approved = this.user.approved;
        } else {
          changes = this.changes;
        }
        this.changes = null;

        const res = await this.$api.users.patchUser(
          this.$route.params.id,
          changes
        );

        if (res) {
          console.log("User saved ", res);

          // Si nouvel usager créé, on redirige à sa page
          if (this.$route.params.id == -1) {
            this.$router.push("/user/" + res.uid);
          }
        }
      } catch (err) {
        alert(parseError(err));
      } finally {
        this.saving = false;
      }
    },
    cancel() {
      this.changes = null;

      if (this.$route.params.id != -1) {
        this.reloadUser(this.$route.params.id);
      } else {
        this.$router.push("/users");
      }
    },
    async deleteUser() {
      this.deleteUserDialog = false;

      try {
        await this.$api.users.deleteUser(this.$route.params.id);
        this.$router.push("/users");
      } catch (err) {
        alert(parseError(err));
      }
    },
    async reloadUser(userId) {
      // Si un nouvel usager
      if (userId == -1) {
        // TO TEST...
        const username = this.$route.query.username;
        const email = this.$route.query.email;
        const role = this.$route.query.role;

        const company = this.$route.query.company;
        const phone = this.$route.query.phone;
        const address = this.$route.query.address;
        const city = this.$route.query.city;
        const state = this.$route.query.state;
        const country = this.$route.query.country;
        const postalcode = this.$route.query.postalcode;

        this.changes = {
          infos: {
            company: "",
            phone: "",
            country: null,
            address: "",
            city: "",
            state: null,
            postalcode: "",
          },
        };

        this.user = { displayName: "", singleRole: "admin", email: "" };

        if (username && email && role) {
          this.user.displayName = username;
          this.user.singleRole = role;
          this.user.email = email;
        }

        if (company) {
          this.infos.company = company;
          this.infos.phone = phone;
          this.infos.address = address;
          this.infos.city = city;
          this.infos.postalcode = postalcode;

          if (country) {
            this.infos.country = countries.find((c) => c.name == country);
          }

          if (state) {
            this.infos.state = this.infos.country.states.find(
              (s) => s.name == state
            );
          }

          this.changes.infos = {
            company: company,
            phone: phone,
            address: address,
            city: city,
            country: country,
            state: state,
            postalcode: postalcode,
          };
        }

        if (this.breadcrumbs.length == 0) {
          this.breadcrumbs = [
            {
              text: this.$t("users"),
              href: "/users",
            },
            {
              text: this.$t("create_user"),
              href: "/user/" + userId,
              disabled: true,
            },
          ];
        }

        return;
      }

      try {
        const data = await this.$api.users.getUser(userId);
        this.setSelectedUser(data);
        this.user = data;
        formatUser(this.user);
        if (data.infos) {
          this.infos.company = data.infos.company;
          this.infos.phone = data.infos.phone;
          this.infos.address = data.infos.address;
          this.infos.city = data.infos.city;
          this.infos.postalcode = data.infos.postalcode;

          if (data.infos.country) {
            this.infos.country = countries.find(
              (c) => c.name == data.infos.country
            );
          }

          if (data.infos.state) {
            this.infos.state = this.infos.country.states.find(
              (s) => s.name == data.infos.state
            );
          }
        }

        if (this.breadcrumbs.length == 0) {
          this.breadcrumbs = [
            {
              text: this.$t("users"),
              href: "/users",
            },
            {
              text: this.user.email,
              href: "/user/" + userId,
              disabled: true,
            },
          ];
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    if (this.isAdmin) {
      this.roles = [
        { name: this.$t("admin"), value: "admin" },
        { name: this.$t("tech"), value: "tech" },
        { name: this.$t("installer"), value: "installer" },
        { name: this.$t("user"), value: "user" },
        { name: this.$t("prod"), value: "prod" },
      ];
    } else if(this.isTech) {
      this.roles = [
        { name: this.$t("installer"), value: "installer" },
      ];
    } else {
      this.roles = []
    }

    this.emailRules = [
      (v) => !!v || this.$t("signup.required"),
      (v) => /.+@.+\..+/.test(v) || this.$t("signup.email_not_valid"),
    ];
    this.reloadUser(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.reloadUser(to.params.id);
    next();
  },
};
</script>

<style scoped></style>
