<template>
  <v-btn
    rounded
    @click.native="toggleLang()"
    class="no-print"
    style="text-transform: none"
  >
    {{ $i18n.locale == "fr" ? "English" : "Français" }}
  </v-btn>
</template>

<script>
export default {
  name: "langbutton",
  components: {},
  props: {
    link: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {};
  },
  methods: {
    toggleLang() {
      this.$i18n.locale == "en"
        ? (this.$i18n.locale = "fr")
        : (this.$i18n.locale = "en");
      localStorage.setItem("locale", this.$i18n.locale);
    },
  },
};
</script>
