<template>
  <div>
    <v-container>
      <div class="mb-10 mt-5">
        <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />
        <v-text-field
          v-model="search"
          class="search-txt-field"
          :label="$t('search')"
          rounded
          hide-details
          solo
          @input="debouncedFindLink"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <router-link v-if="isAdmin" to="/link/-1">
          <v-btn elevation="2" class="action-button" fab color="white">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </router-link>
      </div>
      <v-row>
        <v-btn
          :color="!deactivated ? 'primary' : ''"
          rounded
          :elevation="!deactivated ? 0 : 5"
          class="no-transform ml-5"
          @click="(deactivated = false), getLinksQuery()"
        >
          <v-icon class="mr-5">mdi-check</v-icon>
          {{ $t("in_use") }}
        </v-btn>

        <v-btn
          :color="deactivated ? 'primary' : ''"
          class="ml-4 no-transform"
          rounded
          :elevation="deactivated ? 0 : 5"
          @click="(deactivated = true), getLinksQuery()"
        >
          <v-icon class="mr-5">mdi-cloud-off-outline</v-icon>
          {{ $t("deactivated_only") }}
        </v-btn>
      </v-row>
      <div style="height: 40px"></div>
      <v-row
        v-if="links && links.length > 0"
        style="max-height: 75vh; overflow: auto"
      >
        <v-col
          v-for="(link, index) in links"
          :key="index"
          xl="3"
          lg="4"
          md="4"
          sm="6"
          cols="12"
        >
          <LinkBox :link="link" :key="index" />
        </v-col>
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              style="display: inline-block"
              v-model="options.page"
              :length="pageCount"
              circle
              @input="getLinksQuery"
            ></v-pagination>
            <span> Total: {{ totalLinks }}</span>
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader v-else type="table"></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import LinkBox from "../components/LinkBox.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";

import { mapMutations, mapState, mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: "Links",
  components: { LinkBox, Breadcrumbs },
  data: () => {
    return {
      breadcrumbs: [],
      search: "",
      totalLinks: 0,
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      deactivated: false,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalLinks / this.options.itemsPerPage);
    },
    ...mapGetters(["isAdmin"]),
    ...mapState({
      users: (state) => state.users,
      links: (state) => state.links,
    }),
    filteredLinks() {
      return this.links.filter((link) => {
        return (
          link.manufacturer
            ?.toLowerCase()
            .includes(this.search.toLowerCase()) ||
          link.nickname?.toLowerCase().includes(this.search.toLowerCase()) ||
          link.serial?.toLowerCase().includes(this.search.toLowerCase()) ||
          link.uuid?.toLowerCase().includes(this.search.toLowerCase()) ||
          this.search === ""
        );
      });
    },
  },
  methods: {
    debouncedFindLink: debounce(function () {
      this.findLink();
    }, 300), // Adjust the debounce delay as needed
    async getLinks() {
      try {
        const data = await this.$api.links.getLinks();
        this.setLinks(data);
      } catch (err) {
        console.log(err);
      }
    },
    async getLinksQuery() {
      try {
        const data = await this.$api.links.getLinksQuery({
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          searchQuery: this.search,
          deactivated: this.deactivated,
        });
        this.setLinks(data.links);
        this.totalLinks = data.total;
      } catch (err) {
        console.log(err);
      }
    },
    async findLink() {
      this.options.page = 1; // Reset to first page on search
      this.getLinksQuery();
    },
    ...mapMutations({
      setUsers: "setUsers",
      setLinks: "setLinks",
    }),
  },
  mounted() {
    this.breadcrumbs = [
      {
        text: "Links",
        href: "/links",
        disabled: true,
      },
    ];
    this.getLinksQuery();
  },
};
</script>

<style scoped>
.action-button {
  display: inline-block;
  margin-right: 25px;
  height: 48px;
  width: 48px;
  vertical-align: top;
  margin-top: 7px;
  margin-left: 8px;
}
.search-txt-field {
  width: 250px;
  display: inline-block;
  max-width: 50vw;
}
</style>
