import Links from './links'
import Users from './users'
import Approbations from './approbations'

export default function ($axios) {
    return {
        users: Users($axios),
        links: Links($axios),
        approbations: Approbations($axios)
    }
};