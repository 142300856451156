<template>
  <v-container class="no-print">
    <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />

    <div style="float: right">
      <v-dialog
        v-model="approveUser"
        v-if="approbation && $route.params.id != -1"
        max-width="400"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="isAdmin" fab color="white" v-bind="attrs" v-on="on">
            <v-icon color="green"> mdi-account-check </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("confirm_approb") }}
          </v-card-title>
          <v-card-text> {{ $t("approb_warning") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="approveUser = false"> Non </v-btn>
            <v-btn text @click="onConfirmApprob"> Oui </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="deleteApprobDialog"
        v-if="approbation && $route.params.id != -1"
        max-width="400"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="isAdmin"
            style="margin-left: 10px"
            fab
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("confirm_delete") }}
          </v-card-title>
          <v-card-text>{{ $t("approb_warning_delete") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteApprobDialog = false">
              {{ $t("no") }}
            </v-btn>
            <v-btn text @click="removeApprobation" :loading="removing">
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="approbation" class="mt-10">
      <v-card
        style="max-width: 800px; margin: auto"
        v-if="$route.params.id != -1"
      >
        <v-card-subtitle style="padding-bottom: 4px">
          <div>
            <div style="display: inline; width: 30px">
              <v-icon class="mr-4">mdi-information-outline</v-icon>
            </div>
            <div style="display: inline-block">
              <p>{{ $t("infos") }}</p>
            </div>
            <div class="info-main">
              <h4>{{ approbation.email }}</h4>
              <p>{{ approbation.firstname + " " + approbation.lastname }}</p>
              <p>{{ approbation.company }}</p>
              <p>
                {{ approbation.phone }}
                <br />
                {{ approbation.address }}
                <br />
                {{ approbation.city }}
                <br />
                {{ approbation.postalcode }}
                <br />
                {{ approbation.state }}
                <br />
                {{ approbation.country }}
              </p>
              <p>{{ "IP : " + approbation.ip }}</p>
              <p>{{ approbation.createdAt }}</p>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>

      <div style="height: 25px"></div>
    </div>

    <v-skeleton-loader
      v-else
      type="article, actions"
      style="margin: 15px"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import LinkBox from "../components/LinkBox.vue";
import RemoteLogs from "../components/remote/RemoteLogs.vue";
import LinkGettingStarted from "../components/LinkGettingStarted.vue";
import { parseError, formatUser, getSBP } from "../utils";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Approbation",
  components: { Breadcrumbs, LinkBox, RemoteLogs, LinkGettingStarted },
  data: () => {
    return {
      approbation: null,
      breadcrumbs: [],
      changes: null,
      valid: true,
      deleteApprobDialog: false,
      removing: false,
      dialog: false,
      approveUser: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    onConfirmApprob() {
      this.$router.push({
        path: "/user/-1",
        query: {
          username: `${this.approbation.firstname} ${this.approbation.lastname}`,
          email: this.approbation.email,
          role: "installer",
          company: this.approbation.company,
          phone: this.approbation.phone,
          address: this.approbation.address,
          city: this.approbation.city,
          state: this.approbation.state,
          country: this.approbation.country,
          postalcode: this.approbation.postalcode,
        },
      });
      /* <p>{{ approbation.company }}</p>
              <p>
                {{ approbation.phone }}
                <br />
                {{ approbation.address }}
                <br />
                {{ approbation.city }}
                <br />
                {{ approbation.state }}
                <br />
                {{ approbation.country }}*/
    },
    async removeApprobation() {
      this.removing = true;
      try {
        const res = await this.$api.approbations.removeApprobation(
          this.$route.params.id
        );
        if (res) {
          this.$router.push("/approbations");
          console.log(res);
        }
      } catch (e) {}

      this.deleteApprobDialog = false;
    },
    async reloadApprobation(approbId) {
      try {
        const data = await this.$api.approbations.getApprobation(approbId);
        this.approbation = data;
        if (this.breadcrumbs.length == 0) {
          this.breadcrumbs = [
            {
              text: "Approbations",
              href: "/approbations",
            },
            {
              text: this.approbation.email,
              href: "/approbation/" + approbId,
              disabled: true,
            },
          ];
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.reloadApprobation(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    console.log(
      "beforeRouteUpdate",
      from.params.id,
      to.params.id,
      this.$route.params.id
    );
    this.reloadApprobation(to.params.id);
    next();
  },
};
</script>

<style scoped></style>
