<template>
  <v-row style="height: 100%">
    <v-col cols="6" align="center" justify="center">
      <h2>Recevoir diagnostic</h2>
      <br />
      <div v-if="diagnostic != ''" style="margin-left: 100px; text-align: left">
        <p v-for="(item, key) in parseDiagnostics" :key="key">
          {{ item.text + item.value }}
        </p>
      </div>
      <v-btn icon @click="receiveDiagnosticInterval">
        <v-icon v-if="receivingInterval == null" color="success"
          >mdi-play</v-icon
        >
        <v-icon v-else color="red">mdi-stop</v-icon>
      </v-btn>
      <br />
    </v-col>
    <v-col cols="6" align="center" justify="center">
      <h2>Bypass</h2>
      <v-row style="width: 200px">
        <template v-for="(command, index) in btnCommands">
          <v-col
            :key="command + index"
            cols="6"
            style="width: 100px; height: 100px; display: inline-block"
          >
            <v-btn
              fab
              color="primary"
              style="margin: 25px"
              @mousedown="sendCommand(command.command)"
              @mouseup="sendCommand('stop')"
              @mouseleave="interval ? sendCommand('stop') : null"
            >
              <v-progress-circular
                v-if="command.isPressed"
                indeterminate
                color="white"
                :size="24"
              ></v-progress-circular>
              <img
                v-else-if="command.command != 'stop'"
                width="60px"
                height="60px"
                :src="`../../assets/icons/BoutonSvg.svg`"
              />
              <v-icon v-else color="red">mdi-stop</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="(index + 1) % 2 == 0 && index !== 0"
            cols="12"
            :key="'buttonsinfo' + index"
            align="center"
            justify="center"
          >
            <img
              width="140px"
              style="margin-left: 25px"
              :src="`../../assets/icons/${getUnderIcon(index)}.svg`"
            />
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { COMMANDS } from "@/utils/enums";
import { getIntFromByteHexString, toShort } from "@/utils.js";

export default {
  name: "RemoteDiagnostic",
  components: {},
  props: {},
  data: () => {
    return {
      COMMANDS,
      btnCommands: null,
      interval: null,
      diagnostic: "",
      receivingDiag: false,
      receivingInterval: null,
    };
  },
  mounted() {
    this.btnCommands = [];
    for (const [key, value] of Object.entries(COMMANDS)) {
      this.btnCommands.push({
        command: value,
        isPressed: false,
      });
    }
  },
  methods: {
    getRowTitle(idx) {
      switch (idx) {
        case 0:
          return "TRAVEL";
        case 2:
          return "TILT";
        case 4:
          return "PIVOT";
        default:
          return "";
      }
    },
    getUnderIcon(idx) {
      console.log(idx);
      switch (idx) {
        case 1:
          return "backward_forward_bypass";
        case 3:
          return "downward_upward_bypass";
        case 5:
          return "inward_outward_bypass";
        default:
          return "";
      }
    },
    sendCommand(command) {
      if (command === COMMANDS.STOP) {
        this.btnCommands.forEach((c) => {
          c.isPressed = false;
        });

        if (this.interval) {
          clearInterval(this.interval);
        }

        this.$socket.emit("remote/command", COMMANDS.STOP);

        return;
      } else {
        this.btnCommands.forEach((c) => {
          if (c.command === command) {
            c.isPressed = true;
          } else {
            c.isPressed = false;
          }
        });
      }

      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
        this.interval = setInterval(() => {
          console.log("SEND COMMAND", Date.now());
          this.$socket.volatile.emit(
            "remote/command",
            command == "stop" ? "stop" : "bypass_" + command
          );
        }, 150);
      } else {
        this.interval = setInterval(() => {
          console.log("SEND COMMAND", Date.now());
          this.$socket.volatile.emit(
            "remote/command",
            command == "stop" ? "stop" : "bypass_" + command
          );
        }, 150);
      }
    },
    receiveDiagnostic() {
      const scope = this;
      this.receivingDiag = true;
      this.$socket.volatile.emit("remote/diagnostic");
    },
    receiveDiagnosticInterval() {
      if (this.receivingInterval) {
        clearInterval(this.receivingInterval);
        this.receivingInterval = null;
        return;
      }
      const scope = this;
      this.$socket.off("remote/diagnostic/receive");
      this.$socket.on("remote/diagnostic/receive", (diag) => {
        scope.diagnostic = diag;
        this.receivingDiag = false;
      });
      this.receivingInterval = setInterval(() => {
        scope.receiveDiagnostic();
      }, 1000);
    },
    getButtonStyle(index) {
      return index !== 0 && index % 2 === 0
        ? "display: block"
        : "display: inline-block";
    },
  },
  beforeDestroy() {
    if (this.receivingInterval) {
      clearInterval(this.receivingInterval);
      this.receivingInterval = null;
    }
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  computed: {
    parseDiagnostics() {
      const array = this.diagnostic;
      let TravelEncoderCount,
        TiltEncoderCount,
        PivotEncoderCount,
        MotorCurrent,
        CarBatteryVoltage,
        NineVoltBatteryVoltage,
        ActualDay,
        LasDayR,
        LastDayKTravel,
        LastDayKTilt,
        LastDayKPivot,
        MaintenanceCounter,
        MaintenanceResetCount;

      MotorCurrent = toShort(getIntFromByteHexString(array, 0, 4)) / 10;
      CarBatteryVoltage = (
        toShort(getIntFromByteHexString(array, 4, 4)) / 10
      ).toFixed(2);
      TravelEncoderCount = toShort(getIntFromByteHexString(array, 8, 4));
      TiltEncoderCount = toShort(getIntFromByteHexString(array, 12, 4));
      PivotEncoderCount = toShort(getIntFromByteHexString(array, 16, 4));
      NineVoltBatteryVoltage = getIntFromByteHexString(array, 20, 2) / 10;
      LasDayR = getIntFromByteHexString(array, 22, 4);
      LastDayKTravel = getIntFromByteHexString(array, 26, 4);
      LastDayKTilt = getIntFromByteHexString(array, 30, 4);
      LastDayKPivot = getIntFromByteHexString(array, 34, 4);
      ActualDay = getIntFromByteHexString(array, 38, 4);

      if (array.length >= 46) {
        MaintenanceCounter = getIntFromByteHexString(array, 42, 4);
      } else {
        MaintenanceCounter = 0;
      }

      if (array.length >= 50) {
        MaintenanceResetCount = getIntFromByteHexString(array, 46, 4);
      } else {
        MaintenanceResetCount = 0;
      }

      return [
        { text: "Motor Current: ", value: MotorCurrent },
        { text: "Car Battery Voltage: ", value: CarBatteryVoltage },
        { text: "Travel Encoder: ", value: TravelEncoderCount },
        { text: "Tilt Encoder: ", value: TiltEncoderCount },
        { text: "Pivot Encoder: ", value: PivotEncoderCount },
        //{ text: "9V Battery Voltage: ", value: NineVoltBatteryVoltage },
        //{ text: "Actual Day: ", value: ActualDay },
        //{ text: "Last Calculation r: ", value: LasDayR },
        //{ text: "Last Calculation k Travel: ", value: LastDayKTravel },
        //{ text: "Last Calculation k Tilt: ", value: LastDayKTilt },
        //{ text: "Last Calculation k Pivot: ", value: LastDayKPivot },
        { text: "Maintenance Counter: ", value: MaintenanceCounter },
        { text: "Maintenance Reset Count: ", value: MaintenanceResetCount },
      ];
    },
  },
};
</script>
