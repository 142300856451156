<template>
  <div>
    <v-container>
      <div class="mb-10 mt-5">
        <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />
        <v-text-field
          v-model="search"
          class="search-txt-field"
          :label="$t('search')"
          rounded
          hide-details
          solo
          @input="debouncedFindUser"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="action-button"
              fab
              color="white"
              v-bind="attrs"
              v-on="on"
              style="margin-left: 25px"
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          <v-card style="padding: 15px">
            <h3 style="padding: 0px 0px 15px 0px">{{ $t("filters") }}</h3>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col sm="6" cols="12">
                  <h4 style="padding: 10px 0px 0px 0px">{{ $t("roles") }}</h4>
                  <v-checkbox
                    v-model="filterRoleAdmin"
                    :label="$t('admin')"
                    color="primary"
                    hide-details
                    @change="getUsersQuery"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filterRoleTech"
                    :label="$t('tech')"
                    color="primary"
                    hide-details
                    @change="getUsersQuery"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filterRoleProd"
                    :label="$t('prod')"
                    color="primary"
                    hide-details
                    @change="getUsersQuery"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filterRoleUser"
                    :label="$t('user')"
                    color="primary"
                    hide-details
                    @change="getUsersQuery"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filterRoleInstaller"
                    :label="$t('installer')"
                    color="primary"
                    hide-details
                    @change="getUsersQuery"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-menu>
        <router-link to="/user/-1">
          <v-btn class="action-button" fab color="white">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </router-link>
      </div>

      <v-row
        v-if="users && users.length > 0"
        style="max-height: 75vh; overflow: auto"
      >
        <v-col
          v-for="(user, index) in users"
          :key="index"
          xl="4"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <UserBox :user="user" :key="index" />
        </v-col>
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              style="display: inline-block"
              v-model="options.page"
              :length="pageCount"
              circle
              @input="getUsersQuery"
            ></v-pagination>
            <span> Total: {{ totalUsers }}</span>
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader v-else type="table"></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import UserBox from "../components/UserBox.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";

import { mapMutations, mapState } from "vuex";

import debounce from "lodash/debounce";
export default {
  name: "Users",
  components: { UserBox, Breadcrumbs },
  data: () => {
    return {
      breadcrumbs: [],
      search: "",
      filterRoleAdmin: true,
      filterRoleTech: true,
      filterRoleUser: false,
      filterRoleProd: true,
      filterRoleInstaller: true,
      totalUsers: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  computed: {
    roles() {
      const roles = [];
      if (this.filterRoleAdmin) roles.push("admin");
      if (this.filterRoleTech) roles.push("tech");
      if (this.filterRoleUser) roles.push("user");
      if (this.filterRoleProd) roles.push("prod");
      if (this.filterRoleInstaller) roles.push("installer");
      return roles.join(","); // Convert array to comma-separated string
    },
    pageCount() {
      return Math.ceil(this.totalUsers / this.options.itemsPerPage);
    },
    ...mapState({
      users: (state) => state.users,
      links: (state) => state.links,
    }),
  },
  methods: {
    debouncedFindUser: debounce(function () {
      this.findUser();
    }, 300),
    async getUsersQuery() {
      try {
        const data = await this.$api.users.getUsersQuery({
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          searchQuery: this.search,
          roles: this.roles,
        });
        this.setUsers(data.users);
        this.totalUsers = data.total;
      } catch (err) {
        console.log(err);
      }
    },
    async findUser() {
      this.options.page = 1; // Reset to first page on search
      this.getUsersQuery();
    },
    ...mapMutations({
      setUsers: "setUsers",
      setLinks: "setLinks",
    }),
  },
  mounted() {
    this.breadcrumbs = [
      {
        text: this.$t("users"),
        href: "/users",
        disabled: true,
      },
    ];
    this.getUsersQuery();
  },
};
</script>

<style scoped>
.action-button {
  display: inline-block;
  margin-right: 25px;
  height: 48px;
  width: 48px;
  vertical-align: top;
  margin-top: 3px;
}
.search-txt-field {
  width: 250px;
  display: inline-block;
  max-width: 50vw;
}
</style>
