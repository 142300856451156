export default function ($axios) {
  return {
    async factoryResetLink(serial) {
      const res = await $axios({
        method: "post",
        responseType: "json",
        url: "/api/factoryreset/links/" + serial,
      });

      if (res) {
        return res;
      } else {
        throw "Error Link";
      }
    },
    async toggleActivationLink(serial) {
      const res = await $axios({
        method: "post",
        responseType: "json",
        url: "/api/activate/links/" + serial,
      });

      if (res) {
        return res;
      } else {
        throw "Error Link";
      }
    },
    async isLinkAlreadyPresent(serial, manufacturer) {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/available/links",
        params: {
          serial,
          manufacturer,
        },
      });

      if (res) {
        return res;
      } else {
        throw "Error Link";
      }
    },
    async addLink(uuid, serial, manufacturer) {
      const res = await $axios({
        method: "post",
        responseType: "json",
        url: "/api/links",
        data: { uuid, serial, manufacturer },
      });

      if (res) {
        return res;
      } else {
        throw "Error adding Link";
      }
    },
    async removeLink(serial) {
      const res = await $axios({
        method: "delete",
        responseType: "json",
        url: "/api/links/" + serial,
      });

      if (res) {
        return res;
      } else {
        throw "Error removing Link";
      }
    },

    async getLinksQuery(options) {
      const { page, itemsPerPage, searchQuery, deactivated } = options;

      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/linksQuery",
        params: {
          page,
          count: itemsPerPage,
          search: searchQuery,
          deactivated,
        },
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting links";
      }
    },

    async getLinks() {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/links",
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting links";
      }
    },
    async findLink() {},
    async getLink(id) {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: `/api/links/${id}`,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting link";
      }
    },
    async getLinkByManufacturerId(id) {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: `/api/manufacturer/links/${id}`,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting link";
      }
    },
  };
}
