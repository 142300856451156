export default function ($axios) {
  return {
    async search(searchTerm) {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/search/" + searchTerm,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting Search";
      }
    },
    async getUsers() {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/users",
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting Users";
      }
    },
    async getUsersQuery(options) {
      const { page, itemsPerPage, searchQuery, roles } = options;

      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/usersQuery",
        params: {
          page,
          count: itemsPerPage,
          search: searchQuery,
          roles
        },
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting users";
      }
    },
    async getUsers() {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/users",
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting Users";
      }
    },
    async getUser(userId) {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/user/" + userId,
      });
      if (res) {
        return res.data;
      } else {
        throw "Error getting User " + userId;
      }
    },
    async patchUser(userId, changes) {
      const res = await $axios({
        method: "patch",
        responseType: "json",
        url: "/api/users/" + userId,
        data: changes,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error patching User " + userId;
      }
    },
    async deleteUser(userId) {
      const res = await $axios({
        method: "delete",
        responseType: "json",
        url: "/api/users/" + userId,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error deleting User " + userId;
      }
    },
    async resetPassword(username) {
      const res = await $axios({
        method: "post",
        responseType: "json",
        url: "/api/user/resetpassword",
        data: { username },
      });

      if (res) {
        return res.data;
      } else {
        throw "Error reset pass " + username;
      }
    },
  };
}
