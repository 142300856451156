export const SCREENSTATES = {
    REMOTE_REQUEST: 0,
    REMOTE_HOME: 1,
    REMOTE_BOOTLOAD: 2,
    REMOTE_PRESETS: 3,
    REMOTE_LOGS: 4,
    REMOTE_DIAGNOSTIC: 5,
    REMOTE_CONSOLE: 6,
};

export const REMOTESTATES = {
    REMOTE_START: 0,
    REMOTE_WAITING: 1,
    REMOTE_ACCEPTED: 2,
    REMOTE_CANCELLED: 3
};

export const BOOTSTATE = {
    BOOT_START: 0,
    BOOT_SENDFILE: 1,
    BOOT_WAITING: 2,
    BOOT_PROGRESS: 3,
    BOOT_DONE: 4
};

export const PRESETSTATE = {
    PRESET_START: 0,
    PRESET_SENDFILE: 1,
    PRESET_WAITING: 2,
    PRESET_PROGRESS: 3,
    PRESET_DONE: 4
};

export const COMMANDS = {
    BACKWARD: "backward",
    FORWARD: "forward",
    DOWNWARD: "downward",
    UPWARD: "upward",
    INWARD: "inward",
    OUTWARD: "outward",
    STOP: "stop"
};