<template>
  <v-row align="center" justify="center" style="height: 100%">
    <v-col cols="6" align="center">
      <h2>Mise à jour du micrologiciel</h2>
      <p>
        {{ getBootloadTitle }}
      </p>
      <v-file-input
        v-if="bootloadState == BOOTSTATE.BOOT_START"
        v-model="files"
        color="deep-purple accent-4"
        counter
        label="Sélectionner un micrologiciel"
        placeholder="Sélectionner le fichier"
        prepend-icon="mdi-chip"
        accept=".cyacd2"
        outlined
        :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
            v-if="index < 2"
            color="deep-purple accent-4"
            dark
            label
            small
          >
            {{ text }}
          </v-chip>

          <span
            v-else-if="index === 2"
            class="text-overline grey--text text--darken-3 mx-2"
          >
            +{{ files.length - 2 }} File(s)
          </span>
        </template>
      </v-file-input>
      <v-progress-linear
        v-if="
          bootloadState != BOOTSTATE.BOOT_START &&
          bootloadState != BOOTSTATE.BOOT_DONE
        "
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
        style="width: 200px"
      ></v-progress-linear>
      <v-btn
        rounded
        v-if="bootloadState == BOOTSTATE.BOOT_START"
        :disabled="files.length == 0"
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('onClick', files)"
      >
        Commencer la mise à jour
      </v-btn>
       <v-btn
        rounded
        v-if="bootloadState == BOOTSTATE.BOOT_DONE"
        :disabled="files.length == 0"
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('reset')"
      >
        Recommencer
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { SCREENSTATES, REMOTESTATES, BOOTSTATE } from "../../utils/enums";

export default {
  name: "RemoteBootload",
  components: {},
  props: {
    bootloadState: {
      type: Number,
      default: BOOTSTATE.BOOT_START
    },
    bootloadProgress: {
      type: String,
      default: "0"
    },
    bootloadResp: {
      type: String,
      default: ""
    },
  },
  data: () => {
    return {
      SCREENSTATES,
      REMOTESTATES,
      BOOTSTATE,
      files: [],
    };
  },
  methods: {},
  computed: {
    getBootloadTitle() {
      switch (this.bootloadState) {
        case BOOTSTATE.BOOT_START:
          return "";
        case BOOTSTATE.BOOT_SENDFILE:
          return "Téléchargement de la mise à jour en cours...";
        case BOOTSTATE.BOOT_WAITING:
          return "Début de la mise à jour... Redémarrage du Link...";
        case BOOTSTATE.BOOT_PROGRESS:
          return `Mise à jour en cours ${this.bootloadProgress}%... Veuillez patienter...`;
        case BOOTSTATE.BOOT_DONE:
          if (this.bootloadResp == "Success") {
            return "Mise à jour terminée avec succès !.";
          } else {
            return "Erreur durant la mise à jour. Veuillez redémarrer l'appareil client.";
          }
      }
    }
  }
};
</script>
