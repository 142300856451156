import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";

import { getAuth } from "firebase/auth";

import { formatUser } from "../utils";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    interval: null,
    selectedUser: null,

    users: [],
    links: [],
    approbations: [],
  },
  getters: {
    isAdmin(state) {
      console.log(state.currentUser, "IS ADMIN ?");
      return state.currentUser
        ? state.currentUser.roles.includes("admin")
        : false;
    },
    isTech(state) {
      console.log(state.currentUser, "IS TECH ?");
      return state.currentUser
        ? state.currentUser.roles.includes("tech")
        : false;
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
      state.users.forEach((user) => {
        formatUser(user);
      });
    },
    setLinks(state, links) {
      state.links = links;
    },
    setApprobations(state, approbations) {
      state.approbations = approbations;
    },
    setSelectedUser(state, user) {
      state.selectedUser = user;
      state.selectedUser.links = [];
      for (let c = 0; c < user.cars.length; c++) {
        for (let l = 0; l < user.cars[c].links.length; l++) {
          state.selectedUser.links.push(user.cars[c].links[l]);
        }
      }
    },
    setLinkConnection(state, { serial, connected }) {
      console.log("Set link connection", serial, connected);
      const idx = state.selectedUser.links.findIndex((i) => i.serial == serial);
      if (idx > -1) {
        state.selectedUser.links[idx].connected = connected;
      }

      return true;
    },
    setCurrentUser(state, user) {
      const scope = this;
      console.log("CURRENT USER", user);
      let roles = [];

      if (user == null) {
        return;
      }
      try {
        let decoded = jwt_decode(user.accessToken);
        roles = decoded.role;
      } catch (e) {}

      user.roles = roles;

      state.currentUser = user;

      if (!state.interval) {
        state.inverval = setInterval(() => {
          getAuth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              scope.$axios.defaults.headers.common["Authorization"] =
                "Bearer " + idToken;
            })
            .catch(function (error) {
              console.log(error);
            });
        }, 1800 * 1000);
      }

      if (user && user.accessToken) {
        this.$axios.defaults.headers.common["Authorization"] =
          "Bearer " + user.accessToken;
      } else {
        this.$axios.defaults.headers.common["Authorization"] = null;
      }
    },
  },
  actions: {},
  modules: {},
});
