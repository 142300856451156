// eslint-disable
import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDPmNN6Q8vTljrqvNJnuJf2E1f7bigna8M",
    authDomain: "adapt-link.firebaseapp.com",
    projectId: "adapt-link",
    storageBucket: "adapt-link.appspot.com",
    messagingSenderId: "53204807033",
    appId: "1:53204807033:web:bef33c1b6fefea00e253a8"
};

firebase.initializeApp(firebaseConfig);

export function getCurrentUser() {
    return new Promise((resolve, reject) => {
        const unsubscribe = getAuth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
}

export default firebase;