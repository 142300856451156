var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"app-bar","app":"","flat":"","color":"white"}},[_c('img',{staticStyle:{"width":"68px","margin-right":"25px"},attrs:{"src":"/adapt_logo_black.svg"}}),_c('v-spacer'),_c('v-toolbar-title',[_c('LangButton',{staticStyle:{"margin-right":"20px"}}),_c('v-menu',{staticStyle:{"border-radius":"18px"},attrs:{"attach":"#app-bar","close-on-content-click":false,"nudge-width":0,"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background":"#131e30","color":"white","box-shadow":"2px 2px 5px rgb(19 30 48 / 25%)"},attrs:{"icon":""}},'v-btn',attrs,false),on),[(_vm.currentUser)?_c('h2',[_vm._v(" "+_vm._s(_vm.currentUser.email[0].toUpperCase())+" ")]):_vm._e()])]}}])},[_c('v-list',{staticStyle:{"min-width":"350px"}},[(_vm.currentUser)?_c('v-list-item',[_c('v-list-item-icon',[(_vm.currentUser.roles.includes('admin'))?_c('v-icon',{staticClass:"userbox-icon"},[_vm._v("mdi-shield-account")]):(_vm.currentUser.roles.includes('tech'))?_c('v-icon',{staticClass:"userbox-icon"},[_vm._v("mdi-account-hard-hat")]):(_vm.currentUser.roles.includes('installer'))?_c('v-icon',{staticClass:"userbox-icon"},[_vm._v("mdi-tools")]):_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_vm._v(_vm._s(_vm.currentUser.roles.includes("admin") ? _vm.$t("admin") : _vm.currentUser.roles.includes("tech") ? _vm.$t("tech") : _vm.currentUser.roles.includes("installer") ? _vm.$t("installer") : "User"))])],1):_vm._e(),(_vm.currentUser)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_vm._v(_vm._s(_vm.currentUser.email))])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_vm._v(_vm._s(_vm.$t("logout")))])],1)],1)],1)],1)],1),_c('v-navigation-drawer',{staticStyle:{"border-radius":"0px 10px 10px 0px"},attrs:{"app":"","dark":"","mini-variant":"","permanent":"","expand-on-hover":"","color":"primary","elevation":6}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-sort-variant")])],1),_c('v-list-item-title',[_c('img',{staticStyle:{"width":"68px","margin-right":"25px"},attrs:{"src":"/adapt_logo_white.svg"}})])],1),_c('v-list-item',{class:{
            'v-list-item--active': _vm.$route.path.startsWith('/findmylink'),
          },attrs:{"link":"","to":"/findmylink"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-tag-search")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("find_a_link")))])],1),(_vm.isAdmin || _vm.isTech)?_c('v-list-item',{class:{ 'v-list-item--active': _vm.$route.path.startsWith('/links') },attrs:{"link":"","to":"/links"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-seat")])],1),_c('v-list-item-title',[_vm._v("LINKs")])],1):_vm._e(),(_vm.isAdmin || _vm.isTech)?_c('v-list-item',{class:{ 'v-list-item--active': _vm.$route.path.startsWith('/user') },attrs:{"link":"","to":"/users"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("users")))])],1):_vm._e(),(_vm.isAdmin || _vm.isTech)?_c('v-list-item',{class:{
            'v-list-item--active': _vm.$route.path.startsWith('/approbations'),
          },attrs:{"link":"","to":"/approbations"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-badge-account-alert-outline")])],1),_c('v-list-item-title',[_vm._v("Approbations")]),_c('v-list-item-action',[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"20px","height":"20px","background":"red","border-radius":"50%","color":"white","font-size":"10px"}},[_vm._v(" "+_vm._s(this.approbations.length)+" ")])])],1):_vm._e()],1),_c('v-spacer'),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',{class:{
            'v-list-item--active': _vm.$route.path.startsWith('/settings'),
          },attrs:{"disabled":"","link":"","to":"/settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("settings")))])],1)],1)],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }