<template>
  <div v-if="state != SCREENSTATES.REMOTE_REQUEST">
    <v-btn
      rounded
      :color="state == SCREENSTATES.REMOTE_HOME ? 'primary' : '#f5f5f5'"
      class="elevated-btn"
      style="margin-left: 24px"
      @click="$emit('onClick', SCREENSTATES.REMOTE_HOME)"
    >
      <v-icon>mdi-controller-classic-outline</v-icon>
      <span style="margin-left: 24px">Commandes</span>
    </v-btn>
    <v-btn
      rounded
      :color="state == SCREENSTATES.REMOTE_DIAGNOSTIC ? 'primary' : '#f5f5f5'"
      class="elevated-btn"
      style="margin-left: 24px"
      @click="$emit('onClick', SCREENSTATES.REMOTE_DIAGNOSTIC)"
    >
      <v-icon>mdi-chart-box-outline</v-icon>
      <span style="margin-left: 24px">Diagnostics</span>
    </v-btn>
    <v-btn
      rounded
      :color="state == SCREENSTATES.REMOTE_BOOTLOAD ? 'primary' : '#f5f5f5'"
      class="elevated-btn"
      style="margin-left: 24px"
      @click="$emit('onClick', SCREENSTATES.REMOTE_BOOTLOAD)"
    >
      <v-icon dark>mdi-chip</v-icon>
      <span style="margin-left: 24px">Mise à jour</span>
    </v-btn>
    <v-btn
      rounded
      :color="state == SCREENSTATES.REMOTE_PRESETS ? 'primary' : '#f5f5f5'"
      class="elevated-btn"
      style="margin-left: 24px"
      @click="$emit('onClick', SCREENSTATES.REMOTE_PRESETS)"
    >
      <v-icon dark>mdi-chip</v-icon>
      <span style="margin-left: 24px">Preset</span>
    </v-btn>
    <v-btn
      rounded
      :color="state == SCREENSTATES.REMOTE_LOGS ? 'primary' : '#f5f5f5'"
      class="elevated-btn"
      style="margin-left: 24px"
      @click="$emit('onClick', SCREENSTATES.REMOTE_LOGS)"
    >
      <v-icon>mdi-format-list-text</v-icon>
      <span style="margin-left: 24px">Historique</span>
    </v-btn>
    <v-btn
      rounded
      :color="state == SCREENSTATES.REMOTE_CONSOLE ? 'primary' : '#f5f5f5'"
      class="elevated-btn"
      style="margin-left: 24px"
      @click="$emit('onClick', SCREENSTATES.REMOTE_CONSOLE)"
    >
      <v-icon>mdi-console</v-icon>
      <span style="margin-left: 24px">Console</span>
    </v-btn>
  </div>
</template>

<script>
import { SCREENSTATES } from "../../utils/enums"

export default {
  name: "RemoteToolbar",
  components: {},
  props: {
    state: {
      type: Number,
      default: SCREENSTATES.REMOTE_HOME
    }
  },
  data: () => {
    return {
      SCREENSTATES
    };
  },
  methods: {}
};
</script>
