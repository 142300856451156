<template>
  <div>
    <router-link :to="'/user/' + user.id">
      <v-hover v-slot="{ hover }" style="cursor: pointer">
        <v-card :elevation="hover ? 5 : 2" class="userbox-card">
          <v-icon class="userbox-icon" v-if="user.singleRole == 'admin'"
            >mdi-shield-account</v-icon
          >
          <v-icon class="userbox-icon" v-if="user.singleRole == 'tech'"
            >mdi-account-hard-hat</v-icon
          >
          <v-icon class="userbox-icon" v-if="user.singleRole == 'installer'"
            >mdi-tools</v-icon
          >
          <v-icon class="userbox-icon" v-if="user.singleRole == 'user'"
            >mdi-account</v-icon
          >
          <v-icon class="userbox-icon" v-if="user.singleRole == 'prod'"
            >mdi-hammer</v-icon
          >
          <div class="right-box">
            <div class="mt-1">
              <v-icon small>mdi-car-seat</v-icon
              ><span class="link-count-text">{{ user.linksCount }}</span>
            </div>
          </div>
          <div class="userbox-info">
            <v-card-title>
              <p class="userbox-text">
                {{ user.displayName ? user.displayName : user.email }}
              </p>
            </v-card-title>
            <v-card-subtitle>
              <p class="userbox-subtitle">
                {{ user.email }}
              </p>
            </v-card-subtitle>
          </div>
        </v-card>
      </v-hover>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "UserBox",
  components: {},
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.userbox-icon {
  vertical-align: inherit;
  display: inline-block;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 15px;
}

.userbox-info {
  min-width: 0;
  padding-top: 10px;
  display: inline-block;
}

.userbox-text {
  font-size: 16px;
  font-weight: 700;
  text-overflow: ellipsis;
  line-height: 10px;
}

.userbox-subtitle {
  font-size: 14px;
  font-weight: 300;
  text-overflow: ellipsis;
  line-height: 10px;
}

.userbox-card {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.right-box {
  position: absolute;
  right: 15px;
  display: block;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.link-count-text {
  font-size: 13px;
  vertical-align: middle;
  color: #757575;
  font-weight: 700;
}
</style>
