<template>
  <v-app>
    <v-main style="margin-top: 10px">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { getAuth } from "firebase/auth";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "App",

  data: () => {
    return {};
  },
  methods: {
    ...mapMutations({
      setCurrentUser: "setCurrentUser",
    }),
  },
  async mounted() {
    const local = localStorage.getItem("locale");
    if (local) {
      this.$i18n.locale = local;
    }

    console.log("App mounted!");

    let auth = await getAuth();

    auth.onAuthStateChanged((user) => {
      console.log("onAuthStateChanged", user);
      this.setCurrentUser(user);
    });
  },
  computed: {
    ...mapGetters(["isAdmin", "isTech"]),
  },
};
</script>

<style scoped>
.v-application {
  font-family: "Oxygen", sans-serif;
}
.v-main {
  background: #fafafa;
}
</style>

<style>
.info-container-no-main {
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "info-icon info-desc info-right";
}
.info-container {
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "info-icon info-desc info-right"
    ". info-main info-main";
}
.info-icon {
  grid-area: info-icon;
  justify-self: center;
}
.info-desc {
  grid-area: info-desc;
}
.info-right {
  justify-self: end;
  grid-area: info-right;
}
.info-main {
  grid-area: info-main;
}

.no-transform {
  text-transform: none !important;
}

.elevated-btn {
  font-size: 12px !important;
  width: 200px !important;
  text-transform: none !important;
  text-align: center !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }
}
</style>
