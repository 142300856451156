<template>
  <v-container class="no-print">
    <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />

    <div style="max-width: 800px; margin: auto">
      <LinkGettingStarted v-model="dialog" :link="{ serial, manufacturer }" />
      <h3>{{ $t("find_a_link") }}</h3>
      <v-container>
        <v-form ref="form" v-model="valid" style="margin-top: 50px">
          <v-text-field
            rounded
            v-model="serial"
            v-mask="'Link-!!!!!!'"
            label="Link-XXXXXX"
            validate-on-blur
            @input="handleInputChange"
            @focus="isFocused = true"
            @blur="isFocused = false"
            :rules="[
              (v) => !!v || $t('serial_required'),
              (v) => /^Link-[A-Z0-9]{6}$/.test(v) || $t('serial_valid'),
            ]"
            solo
          >
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-barcode</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("serial") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <v-text-field
            rounded
            v-model="manufacturer"
            @input="handleManufacturerInputChange"
            @focus="isManufacturerFocused = true"
            @blur="isManufacturerFocused = false"
            v-mask="'LK !! !!!! !!!!'"
            label="LK XX XXXX XXXX"
            validate-on-blur
            :rules="[
              (v) => !!v || this.$t('manufacturer_serial_required'),
              (v) =>
                /^(LK \d{2} \d{4} \d{4})$/.test(v) ||
                this.$t('manufacturer_valid'),
            ]"
            solo
          >
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-barcode-scan</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("manufacturer") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <v-text-field rounded v-model="pass" validate-on-blur solo disabled>
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-bluetooth</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("bluetooth_pass") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <div style="height: 25px"></div>
          <h3>{{ $t("infos") }}</h3>

          <div v-if="link" class="mt-10">
            <br />
            <v-card
              style="max-width: 800px; margin: auto"
              v-if="$route.params.id != -1"
            >
              <v-card-subtitle style="padding-bottom: 4px">
                <div class="info-container-no-main">
                  <div class="info-icon">
                    <v-icon class="mr-10">mdi-account-outline</v-icon>
                  </div>
                  <div class="info-desc">
                    <p style="display: inline">{{ $t("belongs_to") }}</p>
                  </div>
                  <div class="info-right" v-if="link.usersData.length > 0">
                    <p v-for="user in link.usersData" :key="user.id">
                      <a
                        style="text-decoration: underline"
                        @click="$router.push(`/user/${user.id}`)"
                      >
                        {{ user.email }}
                      </a>
                    </p>
                  </div>
                </div>
              </v-card-subtitle>
            </v-card>

            <div style="height: 25px"></div>

            <v-card
              style="max-width: 800px; margin: auto"
              v-if="$route.params.id != -1"
            >
              <v-card-subtitle style="padding-bottom: 4px">
                <div class="info-container">
                  <div class="info-icon">
                    <v-icon class="mr-10">mdi-information-outline</v-icon>
                  </div>
                  <div class="info-desc">
                    <p style="display: inline">{{ $t("infos") }}</p>
                  </div>
                  <div class="info-right"></div>
                  <div class="info-main">
                    <h4>{{ link.nickname }}</h4>
                    <p>{{ link.serial ? link.serial : "" }}</p>
                    <p>{{ link.manufacturer ? link.manufacturer : "" }}</p>
                    <p>{{ link.uuid ? link.uuid : "" }}</p>
                  </div>
                </div>
              </v-card-subtitle>
            </v-card>

            <div style="height: 25px"></div>
            <v-card
              style="max-width: 800px; margin: auto"
              v-if="$route.params.id != -1"
            >
              <v-card-subtitle style="padding-bottom: 4px">
                <div class="info-container">
                  <div class="info-icon">
                    <v-icon class="mr-10">mdi-account-hard-hat-outline</v-icon>
                  </div>
                  <div class="info-desc">
                    <p style="display: inline">
                      {{ $t("installation_infos") }}
                    </p>
                  </div>
                  <div class="info-right">
                    <p v-if="link.usersData.length > 0">
                      {{ $t("installation_infos") }}
                      {{
                        new Date(link.installation / 1000).toLocaleDateString()
                      }}
                    </p>
                  </div>
                  <div class="info-main">
                    <h4>{{ link.installer }}</h4>
                    <p>{{ link.installer_addr }}</p>
                    <p>{{ link.installer_phone }}</p>
                  </div>
                </div>
              </v-card-subtitle>
            </v-card>

            <div style="height: 25px"></div>
          </div>
        </v-form>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import LinkBox from "../components/LinkBox.vue";
import RemoteLogs from "../components/remote/RemoteLogs.vue";
import LinkGettingStarted from "../components/LinkGettingStarted.vue";
import { parseError, formatUser, getSBP } from "../utils";

import { mapState, mapGetters } from "vuex";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default {
  name: "FindALink",
  components: { Breadcrumbs, LinkBox, RemoteLogs, LinkGettingStarted },
  data: () => {
    return {
      link: null,
      users: [],
      breadcrumbs: [],
      changes: null,
      valid: true,
      uuid: "",
      serial: "",
      manufacturer: "",
      adding: false,
      deleteLinkDialog: false,
      removing: false,
      pass: "",
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    handleManufacturerInputChange: debounce(async function (newValue) {
      if (!this.isManufacturerFocused) {
        return;
      }
      // LK XX XXXX XXXX
      console.log("Input value changed:", newValue, newValue.length);
      if (newValue && newValue.length == 15) {
        try {
          const data = await this.$api.links.getLinkByManufacturerId(newValue);
          if (data) {
            this.serial = data.serial;
            console.log(data);
            this.pass = getSBP(data.serial);
            this.users = data.usersData;
            this.link = data;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.serial = "";
        this.pass = "";
        this.users = [];
        this.link = null;
      }
    }, 300),
    handleInputChange: debounce(async function (newValue) {
      if (!this.isFocused) {
        return;
      }
      // Link-D0EE14
      console.log("Input value changed:", newValue);
      if (newValue && newValue.length == 11) {
        try {
          const data = await this.$api.links.getLink(newValue);
          if (data) {
            this.manufacturer = data.manufacturer;
            console.log(data);
            this.pass = getSBP(newValue);
            this.users = data.usersData;
            this.link = data;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.manufacturer = "";
        this.pass = "";
        this.users = [];
        this.link = null;
        //this.serial = "";
      }
    }),
  },
  mounted() {
  },
};
</script>

<style scoped></style>
