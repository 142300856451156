<template>
  <div>
    <v-container>
      <div class="mb-10 mt-5">
        <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />
        <v-text-field
          v-model="search"
          class="search-txt-field"
          :label="$t('search')"
          rounded
          hide-details
          solo
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-row
        v-if="approbations && approbations.length == 0"
        style="max-height: 75vh; overflow: auto"
      >
        {{ "Aucune approbations" }}
      </v-row>
      <v-row
        v-else-if="approbations.length > 0"
        style="max-height: 75vh; overflow: auto"
      >
        <v-col
          v-for="(approbation, index) in filteredApprobations"
          :key="index"
          xl="3"
          lg="4"
          md="4"
          sm="6"
          cols="12"
        >
          <ApprobationBox :approbation="approbation" :key="index" />
        </v-col>
      </v-row>
      <v-skeleton-loader v-else type="table"></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script>
import ApprobationBox from "../components/ApprobationBox.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "Approbations",
  components: { ApprobationBox, Breadcrumbs },
  data: () => {
    return {
      breadcrumbs: [
        {
          text: "Approbations",
          href: "/approbations",
          disabled: true,
        },
      ],
      search: "",
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState({
      approbations: (state) => state.approbations,
    }),
    filteredApprobations() {
      return this.approbations.filter((approb) => {
        return (
          approb.email?.toLowerCase().includes(this.search.toLowerCase()) ||
          approb.phone?.toLowerCase().includes(this.search.toLowerCase()) ||
          this.search === ""
        );
      });
    },
  },
  methods: {
    async getApprobations() {
      try {
        const data = await this.$api.approbations.getApprobations();
        this.setApprobations(data);
      } catch (err) {
        console.log(err);
      }
    },
    ...mapMutations({
      setApprobations: "setApprobations",
    }),
  },
  mounted() {
    this.getApprobations();
  },
};
</script>

<style scoped>
.action-button {
  display: inline-block;
  margin-right: 25px;
  height: 48px;
  width: 48px;
  vertical-align: top;
  margin-top: 3px;
}
.search-txt-field {
  width: 250px;
  display: inline-block;
  max-width: 50vw;
}
</style>
