<template>
  <v-container class="no-print">
    <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />

    <LinkGettingStarted
      v-if="link && $route.params.id != -1"
      v-model="dialog"
      :link="link"
    />

    <v-dialog
      v-model="deactivateLinkDialog"
      v-if="link && $route.params.id != -1"
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isAdmin || isTech"
          fab
          color="white ml-5"
          style="float: right"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="!link.deactivated" color="red">
            mdi-cloud-off-outline
          </v-icon>
          <v-icon v-else color="green">mdi-cloud-check-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ link.deactivated ? "Reactivate ?" : "Deactivate ?" }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deactivateLinkDialog = false"
            >{{ $t("no") }}
          </v-btn>
          <v-btn text @click="toggleActivationLink" :loading="activate">
            {{ $t("yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="factoryResetDialog"
      v-if="link && $route.params.id != -1"
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isAdmin"
          fab
          color="white ml-5"
          style="float: right"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="orange"> mdi-lock-reset </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("confirm_factory") }}
        </v-card-title>
        <v-card-text> {{ $t("confirm_factory_link") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="factoryResetDialog = false"
            >{{ $t("no") }}
          </v-btn>
          <v-btn text @click="factoryResetLink" :loading="factory">
            {{ $t("yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteLinkDialog"
      v-if="link && $route.params.id != -1"
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isAdmin"
          fab
          color="white"
          style="float: right"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="red"> mdi-delete </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("confirm_delete") }}
        </v-card-title>
        <v-card-text> {{ $t("confirm_delete_link") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteLinkDialog = false">{{ $t("no") }} </v-btn>
          <v-btn text @click="removeLink" :loading="removing">
            {{ $t("yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <br />

    <div v-if="link" class="mt-10">
      <br />
      <v-card
        color="red"
        style="max-width: 800px; margin: auto"
        v-if="$route.params.id != -1 && link.deactivated"
      >
        <v-card-subtitle style="padding-bottom: 12px; color: #fff">
          <v-icon color="white"> mdi-cloud-off-outline </v-icon>
          <p style="display: inline; margin-left: 35px">
            {{ $t("deactivated_link") }}
          </p>
          <p style="margin-left: 62px" v-if="link.reason">
            {{ link.reason ? $t("reason") + link.reason : "" }}
            <br />
            <router-link
              style="color: white"
              v-if="linkId"
              :to="`/link/${linkId}`"
              >{{ linkId }}</router-link
            >
          </p>
        </v-card-subtitle>
      </v-card>

      <br />

      <v-card
        style="max-width: 800px; margin: auto"
        v-if="$route.params.id != -1"
      >
        <v-card-subtitle style="padding-bottom: 12px">
          <div class="info-container-no-main">
            <div class="info-icon">
              <v-icon class="mr-10">mdi-account-outline</v-icon>
            </div>
            <div class="info-desc">
              <p style="display: inline">{{ $t("belongs_to") }}</p>
            </div>
            <div class="info-right" v-if="link.usersData.length > 0">
              <p v-for="user in link.usersData" :key="user.id">
                <a
                  style="text-decoration: underline"
                  @click="$router.push(`/user/${user.id}`)"
                >
                  {{ user.email }}
                </a>
              </p>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>

      <div style="height: 25px"></div>
      <v-card
        style="max-width: 800px; margin: auto"
        v-if="$route.params.id != -1"
      >
        <v-card-subtitle style="padding-bottom: 4px">
          <div>
            <div style="display: inline">
              <v-icon class="mr-10">mdi-information-outline</v-icon>
            </div>
            <p style="display: inline">{{ $t("infos") }}</p>
            <br />
            <br />
            <div>
              <p>{{ $t("nickname") }}</p>
              <h4>{{ link.nickname ? link.nickname : "" }}</h4>
              <p>{{ $t("serial") }}</p>
              <h4>&nbsp;&nbsp;{{ link.serial ? link.serial : "" }}</h4>
              <p>{{ $t("manufacturer") }}</p>
              <h4>
                &nbsp;&nbsp;{{ link.manufacturer ? link.manufacturer : "" }}
              </h4>
              <p>{{ $t("uuid") }}</p>
              <h4>&nbsp;&nbsp;{{ link.uuid ? link.uuid : "" }}</h4>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>

      <div style="height: 25px"></div>
      <v-card
        style="max-width: 800px; margin: auto"
        v-if="$route.params.id != -1"
      >
        <v-card-subtitle style="padding-bottom: 4px">
          <div>
            <div style="display: inline">
              <v-icon class="mr-10">mdi-account-hard-hat-outline</v-icon>
            </div>
            <p style="display: inline">{{ $t("installation_infos") }}</p>
            <br />
            <br />
            <div>
              <p>{{ $t("installer_name") }}</p>
              <h4>{{ link.installer }}</h4>
              <p>{{ $t("installer_addr") }}</p>
              <h4>{{ link.installer_addr }}</h4>
              <p>{{ $t("installer_phone") }}</p>
              <h4>{{ link.installer_phone }}</h4>
              <p>{{ $t("installation_date") }}</p>
              <h4 v-if="link.installation != '' && link.installation != 0">
                {{ new Date(link.installation / 1000).toLocaleDateString() }}
              </h4>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>

      <div style="height: 25px"></div>
      <v-card
        style="max-width: 800px; margin: auto"
        v-if="$route.params.id != -1"
      >
        <v-card-subtitle style="padding-bottom: 12px">{{
          $t("history")
        }}</v-card-subtitle>
        <RemoteLogs v-if="link.logs" :logs="link.logs" />
      </v-card>
    </div>

    <div
      v-else-if="$route.params.id == -1"
      style="max-width: 800px; margin: auto"
    >
      <LinkGettingStarted v-model="dialog" :link="{ serial, manufacturer }" />
      <h3>{{ $t("create_link") }}</h3>
      <v-container>
        <v-form ref="form" v-model="valid" style="margin-top: 50px">
          <v-text-field
            v-model="serial"
            v-mask="'Link-!!!!!!'"
            label="Link-XXXXXX"
            :rules="[
              (v) => !!v || $t('serial_required'),
              (v) => /^Link-[A-Z0-9]{6}$/.test(v) || $t('serial_valid'),
            ]"
            solo
            validate-on-blur
            :append-icon="serialValid && serial.length == 11 ? 'mdi-check' : ''"
            :error="!serialValid"
            :error-messages="
              !serialValid
                ? ['Serial is not available']
                : serial.length < 11
                ? [$t('serial_valid')]
                : []
            "
            @input="verifySerialAvailable"
          >
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-barcode</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("serial") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <v-text-field
            v-model="manufacturer"
            v-mask="'LK !! !!!! !!!!'"
            label="LK XX XXXX XXXX"
            validate-on-blur
            :rules="[
              (v) => !!v || this.$t('manufacturer_serial_required'),
              (v) =>
                /^(LK \d{2} \d{4} \d{4})$/.test(v) || $t('manufacturer_valid'),
            ]"
            solo
            :append-icon="
              manufacturerValid && manufacturer.length == 15 ? 'mdi-check' : ''
            "
            :error="!manufacturerValid"
            :error-messages="
              !manufacturerValid
                ? ['LINK unit serial number is not available']
                : manufacturer.length < 15
                ? [$t('manufacturer_valid')]
                : []
            "
            @input="verifyManufacturerAvailable"
          >
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-barcode-scan</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("manufacturer") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <v-text-field v-model="pass" validate-on-blur solo disabled>
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-bluetooth</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("bluetooth_pass") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>
        </v-form>
        <v-row>
          <v-col align="right" cols="12"
            ><v-btn
              rounded
              :disabled="
                serial.length < 11 ||
                manufacturer.length < 15 ||
                !serialValid ||
                !manufacturerValid
              "
              color="primary"
              style="font-size: 12px; width: 200px; text-transform: none"
              @click="addLink"
              :loading="adding"
            >
              {{ $t("add") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-skeleton-loader
      v-else
      type="article, actions"
      style="margin: 15px"
    ></v-skeleton-loader>

    <v-snackbar :timeout="-1" v-model="errorSnackbar" color="red accent-2">
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="white"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import LinkBox from "../components/LinkBox.vue";
import RemoteLogs from "../components/remote/RemoteLogs.vue";
import LinkGettingStarted from "../components/LinkGettingStarted.vue";
import { parseError, formatUser, getSBP } from "../utils";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Link",
  components: { Breadcrumbs, LinkBox, RemoteLogs, LinkGettingStarted },
  data: () => {
    return {
      link: null,
      users: [],
      breadcrumbs: [],
      changes: null,
      valid: true,
      uuid: "",
      serial: "",
      manufacturer: "",
      adding: false,
      deleteLinkDialog: false,
      factoryResetDialog: false,
      deactivateLinkDialog: false,
      removing: false,
      pass: "",
      dialog: false,
      serialValid: false,
      manufacturerValid: false,
      factory: false,
      activate: false,
      errorSnackbar: false,
      errorText: "",
    };
  },
  watch: {
    serial(name) {
      this.pass = getSBP(name);
    },
  },
  computed: {
    formattedReason() {
      if (!this.link.reason) {
        return "";
      }

      let reason = this.$t("reason") + this.link.reason;

      if (this.linkId) {
        const link = `<router-link style="color: white" to="/link/${this.linkId}">${this.linkId}</router-link>`;
        reason = reason.replace(this.linkId, link);
      }

      return reason;
    },
    linkId() {
      // Use a regular expression to find the Link-XXXXXX pattern
      const match = this.link.reason
        ? this.link.reason.match(/Link-[A-Za-z0-9]+/)
        : null;
      return match ? match[0] : null;
    },
    ...mapGetters(["isAdmin", "isTech"]),
  },
  methods: {
    async toggleActivationLink() {
      try {
        this.activate = true;
        const res = await this.$api.links.toggleActivationLink(
          this.$route.params.id
        );
        if (res.status == 200) {
          this.$router.go();
        } else {
        }
      } catch (e) {
        this.errorText =
          "Un LINK est déjà activé avec le numéro de série de l'unité LINK:" +
          this.link.manufacturer;
        this.errorSnackbar = true;
      }
      this.activate = false;
    },
    async factoryResetLink() {
      this.factory = true;
      try {
        const res = await this.$api.links.factoryResetLink(
          this.$route.params.id
        );
        if (res.status == 200) {
          this.$router.go();
        } else {
        }
      } catch (e) {
        this.errorText = "La réinitialisation a échoué. " + e;
        this.errorSnackbar = true;
      }

      this.factory = false;
    },
    async verifySerialAvailable(newValue) {
      if (newValue.includes("Link-") && newValue.length == 11) {
        const res = await this.$api.links.isLinkAlreadyPresent(
          this.serial,
          this.manufacturer
        );
        if (res.status == 200) {
          if (res.data.serial != null && res.data.serial == "Conflict") {
            this.serialValid = false;
          } else {
            this.serialValid = true;
          }

          if (
            res.data.manufacturer != null &&
            res.data.manufacturer == "Conflict"
          ) {
            this.manufacturerValid = false;
          } else {
            this.manufacturerValid = true;
          }
        }
      }
    },
    async verifyManufacturerAvailable(newValue) {
      if (newValue.includes("LK ") && newValue.length == 15) {
        const res = await this.$api.links.isLinkAlreadyPresent(
          this.serial,
          this.manufacturer
        );
        if (res.status == 200) {
          if (res.data.serial != null && res.data.serial == "Conflict") {
            this.serialValid = false;
          } else {
            this.serialValid = true;
          }

          if (
            res.data.manufacturer != null &&
            res.data.manufacturer == "Conflict"
          ) {
            this.manufacturerValid = false;
          } else {
            this.manufacturerValid = true;
          }
        }
      }
    },
    async removeLink() {
      this.removing = true;
      const res = await this.$api.links.removeLink(this.$route.params.id);
      if (res.status == 200) {
        this.$router.push("/links");
      } else {
        console.log(res);
        // this.$toast.error(parseError(res));
      }
    },

    async addLink() {
      this.adding = true;
      const res = await this.$api.links.addLink(
        this.uuid,
        this.serial,
        this.manufacturer
      );
      console.log(res);
      if (res.status == 200) {
        this.$router.push(`/link/${this.serial}`);
      } else {
        console.log(res);
      }
    },
    async reloadLink(linkId) {
      // Si un nouvel usager
      if (linkId == -1) {
        if (this.breadcrumbs.length == 0) {
          this.breadcrumbs = [
            {
              text: "Links",
              href: "/links",
            },
            {
              text: this.$t("create_link"),
              href: "/link/" + linkId,
              disabled: true,
            },
          ];
        }

        return;
      }

      try {
        const data = await this.$api.links.getLink(linkId);
        console.log("LINK", data);
        this.link = data;
        if (this.breadcrumbs.length == 0) {
          this.breadcrumbs = [
            {
              text: "Links",
              href: "/links",
            },
            {
              text: this.link.serial,
              href: "/link/" + linkId,
              disabled: true,
            },
          ];
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.reloadLink(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    console.log(
      "beforeRouteUpdate",
      from.params.id,
      to.params.id,
      this.$route.params.id
    );
    this.reloadLink(to.params.id);
    next();
  },
};
</script>

<style scoped>
h4 {
  margin-bottom: 20px;
}
</style>
