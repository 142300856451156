<template>
  <div>
    <div style="text-align: center; margin: auto; padding: 130px 0px">
      <img src="/adapt_logo_black.svg" />
    </div>
    <div style="text-align: center; margin: auto">
      <div style="width: 500px; margin: auto">
        <h2 style="text-align: left">{{ modeTitle }}</h2>
        <div style="height: 20px"></div>
        <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
        <v-alert v-if="successMessage" type="success">{{
          successMessage
        }}</v-alert>
        <v-form
          v-if="!successMessage && !errorMessage"
          ref="form"
          v-model="valid"
          v-show="mode === 'resetPassword'"
        >
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :label="$t('new_password')"
            required
            :rules="passwordRules"
          ></v-text-field>
          <!-- Add other password complexity checks -->
          <div style="height: 20px"></div>
          <v-btn
            rounded
            :disabled="!valid"
            @click="resetMyPassword"
            color="primary"
            style="font-size: 12px"
            >{{ $t("reset_password") }}</v-btn
          >
        </v-form>
        <div style="height: 20px"></div>
        <v-btn
          v-if="successMessage || errorMessage"
          rounded
          @click="$router.push('/login')"
          color="primary"
          style="font-size: 12px"
          >{{ $t("return_to_login") }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";

export default {
  data() {
    return {
      mode: "",
      valid: false,
      modeTitle: "",
      errorMessage: "",
      successMessage: "",
      password: "",
      showPassword: false,
      passwordRules: [],
    };
  },
  computed: {
    oobCode() {
      return this.$route.query.oobCode || ""; // Retrieve oobCode from Vue Router
    },
  },
  mounted() {
    this.passwordRules = [
      (v) => !!v || this.$t("pass.required"),
      (v) => (v && v.length >= 8) || this.$t("pass.eightchars"),
      (v) => /\d/.test(v) || this.$t("pass.onenumber"),
      (v) => /[a-z]/.test(v) || this.$t("pass.onelowercase"),
      (v) => /[A-Z]/.test(v) || this.$t("pass.oneuppercase"),
      (v) => /[\W_]/.test(v) || this.$t("pass.onespecial"),
    ];
  },
  created() {
    this.mode = this.$route.query.mode || ""; // Retrieve mode from Vue Router
    this.setModeTitle();
  },
  watch: {
    $route() {
      this.mode = this.$route.query.mode || ""; // Update mode when route changes
      this.setModeTitle();
    },
  },
  methods: {
    resetMyPassword() {
      this.errorMessage = "";
      this.successMessage = "";
      const auth = getAuth();
      var scope = this;
      // Verify the code is valid before resetting the password
      verifyPasswordResetCode(auth, this.oobCode)
        .then(() => {
          confirmPasswordReset(auth, this.oobCode, this.password)
            .then(() => {
              this.successMessage = scope.$t("pass.password_reset");
              setTimeout(() => {
                scope.$router.push("/login");
              }, 5000);
            })
            .catch((error) => {
              this.errorMessage = this.$t("pass.reset_failed");
              console.error("Error resetting password:", error);
            });
        })
        .catch((error) => {
          this.errorMessage = this.$t("pass.invalid_reset_code");
          console.error("Error verifying reset code:", error);
        });
    },
    setModeTitle() {
      switch (this.mode) {
        case "resetPassword":
          this.modeTitle = this.$t("pass.password_reset_title");
          break;
        case "recoverEmail":
          this.modeTitle = this.$t("pass.email_recovery");
          break;
        case "verifyEmail":
          this.modeTitle = this.$t("pass.email_verification");
          break;
        default:
          this.modeTitle = "";
          break;
      }
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
