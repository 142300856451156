import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#131E30",
        secondary: "#D0D0D0",
        accent: "#34A9DB",
      },
    },
  },
});
