<template>
  <div>
   Settings
  </div>
</template>

<script>
export default {
  name: "Settings",
  components: {
  },
  data: () => {
    return {
    };
  },
  methods: {

  }
};
</script>
