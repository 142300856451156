<template>
  <div>
    <div
      ref="consolelist"
      style="height: calc(100vh - 200px); overflow-y: scroll"
    >
      <v-list dense>
        <v-list-item-group v-if="consoleLogs.length > 0">
          <v-list-item v-for="(log, index) in consoleLogs" :key="index">
            <v-list-item-content>
              <v-list-item-title>{{ log }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>

    <v-text-field
      v-model="command"
      @keyup.enter="sendConsoleCommand"
      style="width: 100%"
      label="Console"
      outlined
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "RemoteConsole",
  components: {},
  props: {},
  data: () => {
    return {
      command: "",
      consoleLogs: [],
    };
  },
  mounted() {
    this.$socket.off("remote/console/receive");
    this.$socket.on("remote/console/receive", (answer) => {
      // Handle received data and update consoleLogs
      this.consoleLogs.push(answer);
      this.scrollConsoleDown();
    });
  },
  methods: {
    sendConsoleCommand() {
      this.$socket.emit("remote/console/send", this.command);

      // Handle sending command and update consoleLogs
      this.consoleLogs.push(`> ${this.command}`);
      this.scrollConsoleDown();

      // Clear the input after sending the command
      this.command = "";
    },
    scrollConsoleDown() {
      this.$nextTick(() => {
        // Scroll to the bottom of the consoleLogs list
        const consoleList = this.$refs.consolelist;
        consoleList.scrollTop = consoleList.scrollHeight;
      });
    },
  },
  computed: {},
};
</script>

<style>
.console-container {
  max-height: 100vh;
  overflow-y: auto;
  background-color: black;
  color: white;
}

.input-container {
  display: flex;
  align-items: center;
  height: 100px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.console-prompt {
  margin-right: 8px;
  color: #00ff00; /* Set the color of the prompt as desired */
}

::v-deep.console-input {
  background-color: black;
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
