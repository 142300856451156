<template>
  <v-dialog
    :value="true"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar elevation="0">
        <v-btn icon @click="goBack">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Remote</v-toolbar-title>
        <RemoteToolbar
          v-if="state != SCREENSTATES.REMOTE_REQUEST"
          :state="state"
          @onClick="toolbarClicked"
        />
        <v-spacer></v-spacer>
      </v-toolbar>
      <div>
        <div class="remote-container">
          <div class="main-container">
            <div
              v-if="state == SCREENSTATES.REMOTE_REQUEST"
              style="height: 100%"
            >
              <RemoteStart
                :remote-state="remoteState"
                @onClick="handleStartRemote"
              />
            </div>
            <div v-else-if="state == SCREENSTATES.REMOTE_HOME">
              <RemoteCommands />
            </div>

            <div v-else-if="state == SCREENSTATES.REMOTE_DIAGNOSTIC">
              <RemoteDiagnostic />
            </div>
            <div
              v-else-if="state == SCREENSTATES.REMOTE_BOOTLOAD"
              style="height: 100%"
            >
              <RemoteBootload
                :bootload-progress="bootloadProgress"
                :bootload-resp="bootloadResp"
                :bootload-state="bootloadState"
                @onClick="handleStartBootload"
                @reset="resetBootload"
              />
            </div>
            <div
              v-else-if="state == SCREENSTATES.REMOTE_PRESETS"
              style="height: 100%"
            >
              <RemotePresets
                :preset-resp="presetResp"
                :preset-state="presetState"
                :preset-progress="presetProgress"
                @onClick="handleStartPreset"
                @resetPage="resetPresets"
                @forcePreset="forcePreset"
                @onZeroClick="handleZero"
              />
            </div>
            <div v-else-if="state == SCREENSTATES.REMOTE_LOGS">
              <v-btn
                rounded
                color="primary"
                class="elevated-btn"
                @click="getLogs"
                :loading="isReceivingLogs"
              >
                <v-icon>mdi-format-list-text</v-icon>
                <span style="margin-left: 24px">Recevoir historique</span>
              </v-btn>
              <RemoteLogs :logs="logs" />
            </div>
            <div v-else-if="state == SCREENSTATES.REMOTE_CONSOLE">
              <RemoteConsole />
            </div>
          </div>
          <div class="messages-container">
            <v-list>
              <v-list-item
                v-for="(message, index) in linkMessage"
                :key="index"
                style="
                  font-size: 11px;
                  background: #131e30;
                  color: #fff;
                  border-radius: 25px;
                  margin-top: 5px;
                "
                color="primary"
              >
                {{ message }}
              </v-list-item>
            </v-list>
          </div>
        </div>
      </div>
    </v-card>
    <v-btn
      v-if="!isInfoOpened"
      :color="'primary'"
      @click="isInfoOpened = true"
      fab
      class="info-btn"
      size="large"
    >
      <v-icon>mdi-information</v-icon>
    </v-btn>

    <v-sheet
      v-else
      class="user-status-window pa-4 mx-auto"
      elevation="12"
      max-width="600"
      rounded="lg"
      width="100%"
    >
      <span v-if="selectedUser.links.length > 0">
        <span v-for="link in selectedUser.links" :key="link.serial"
          >{{
            link.nickname + " :: " + link.serial + " :: " + getSBP(link.serial)
          }}
          <div
            :style="{ backgroundColor: link.connected ? 'green' : 'red' }"
            class="connection-status"
          ></div>
          <br
        /></span>
      </span>
      <p>
        {{ isUserConnected }}
      </p>
      <p>
        {{ powerModeText }}
      </p>

      <v-divider class="mb-4"></v-divider>

      <div class="text-end">
        <v-btn
          class="text-none"
          color="primary"
          rounded
          variant="flat"
          width="90"
          @click="isInfoOpened = false"
        >
          Close
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { getAuth } from "firebase/auth";
import { mapState, mapMutations } from "vuex";

import { getSBP } from "../utils";

import RemoteToolbar from "../components/remote/RemoteToolbar.vue";
import RemoteStart from "../components/remote/RemoteStart.vue";
import RemoteBootload from "../components/remote/RemoteBootload.vue";
import RemotePresets from "../components/remote/RemotePresets.vue";
import RemoteLogs from "../components/remote/RemoteLogs.vue";
import RemoteCommands from "../components/remote/RemoteCommands.vue";
import RemoteDiagnostic from "../components/remote/RemoteDiagnostic.vue";
import RemoteConsole from "@/components/remote/RemoteConsole.vue";

import {
  SCREENSTATES,
  BOOTSTATE,
  REMOTESTATES,
  PRESETSTATE,
} from "../utils/enums";

export default {
  components: {
    RemoteToolbar,
    RemoteStart,
    RemoteBootload,
    RemoteLogs,
    RemoteCommands,
    RemotePresets,
    RemoteDiagnostic,
    RemoteConsole,
  },
  data() {
    return {
      SCREENSTATES,
      REMOTESTATES,
      BOOTSTATE,
      isConnected: false,
      socketMessage: "",
      linkMessage: [],
      msg: "",
      userId: undefined,
      remoteAccepted: false,
      image: "",
      state: SCREENSTATES.REMOTE_REQUEST,
      remoteState: REMOTESTATES.REMOTE_START,
      bootloadState: BOOTSTATE.BOOT_START,
      bootloadProgress: "0",
      bootloadResp: "",
      logs: {},
      isReceivingLogs: false,
      users: [],
      presetResp: "",
      presetState: PRESETSTATE.PRESET_START,
      presetProgress: "",
      powerMode: "0",
      isInfoOpened: true,
    };
  },
  created() {
    this.login();

    //io.in(user.room).emit('link/connect', { serial: message, connected: false })

    this.$socket.off("link/connect");
    this.$socket.on("link/connect", async (msg) => {
      const resp = this.setLinkConnection(msg);
      this.$forceUpdate();
    });

    this.$socket.off("connect");
    this.$socket.on("connect", () => {
      this.login();
    });

    this.$socket.off("disconnect");
    this.$socket.on("disconnect", () => {
      this.isConnected = false;
    });

    this.$socket.off("message");
    this.$socket.on("message", (data) => {
      const date = new Date();
      this.addToMessages(data.text + " " + date.toLocaleString("fr-CA"));
    });

    /* REMOTE REQUEST RESPONSES */
    this.$socket.off("remoteRequest");
    this.$socket.on("remoteRequest", (data) => {
      const date = new Date();
      this.addToMessages(
        "Remote request : " + data.text + " " + date.toLocaleString("fr-CA")
      );
    });

    this.$socket.off("remoteRequest/accept");
    this.$socket.on("remoteRequest/accept", (data) => {
      this.remoteState = REMOTESTATES.REMOTE_ACCEPTED;
      this.remoteAccepted = true;
      const date = new Date();
      this.addToMessages(data.text + " " + date.toLocaleString("fr-CA"));

      setTimeout(() => {
        this.handleStartRemote();
      }, 2000);
    });

    this.$socket.off("remoteRequest/cancel");
    this.$socket.on("remoteRequest/cancel", (data) => {
      this.state = SCREENSTATES.REMOTE_REQUEST;
      this.remoteState = REMOTESTATES.REMOTE_CANCELLED;
      this.remoteAccepted = false;
      const date = new Date();
      this.linkMessage.unshift(data.text + " " + date.toLocaleString("fr-CA"));
    });

    /* BOOTLOAD RESPONSES */
    this.$socket.off("remote/bootload/start");
    this.$socket.on("remote/bootload/start", () => {
      this.bootloadState = BOOTSTATE.BOOT_WAITING;
    });

    this.$socket.off("remote/bootload/progress");
    this.$socket.on("remote/bootload/progress", (progress) => {
      this.bootloadState = BOOTSTATE.BOOT_PROGRESS;
      this.bootloadProgress = progress;
    });

    this.$socket.off("remote/bootload/end");
    this.$socket.on("remote/bootload/end", (msg) => {
      this.bootloadState = BOOTSTATE.BOOT_DONE;
      this.bootloadResp = msg;
    });

    /* PRESET RESPONSES */
    this.$socket.off("remote/preset/start");
    this.$socket.on("remote/preset/start", () => {
      this.presetState = PRESETSTATE.PRESET_WAITING;
    });

    this.$socket.off("remote/preset/progress");
    this.$socket.on("remote/preset/progress", (progress) => {
      this.presetState = PRESETSTATE.PRESET_PROGRESS;
      this.presetProgress = progress;
    });

    this.$socket.off("remote/preset/end");
    this.$socket.on("remote/preset/end", (msg) => {
      this.presetState = PRESETSTATE.PRESET_DONE;
      this.presetResp = msg;
    });

    /* LOGS RESPONSES */
    this.$socket.off("remote/logs/receive");
    this.$socket.on("remote/logs/receive", (msg) => {
      this.logs = msg;

      this.isReceivingLogs = false;
    });

    this.$socket.off("notification");
    this.$socket.on("notification", (data) => {
      const date = new Date();
      this.addToMessages(
        "* " +
          data.title +
          " " +
          data.description +
          " " +
          date.toLocaleString("fr-CA")
      );
    });

    this.$socket.off("users");
    this.$socket.on("users", (data) => {
      // const date = new Date();
      this.users = data;
    });

    this.$socket.off("link/powerMode/receive");
    this.$socket.on("link/powerMode/receive", (powerMode) => {
      this.powerMode = powerMode;
    });
  },
  methods: {
    ...mapMutations({
      setLinkConnection: "setLinkConnection",
    }),
    getSBP,
    addToMessages(msg) {
      if (this.linkMessage > 100) {
        this.linkMessage = this.linkMessage.slice(0, 100);
      }
      this.linkMessage.unshift(msg);
    },
    goBack() {
      this.cancelRemote();
      this.$router.back();
    },
    toolbarClicked(newState) {
      this.state = newState;
    },
    getLogs() {
      if (this.isReceivingLogs) {
        return;
      }

      this.$socket.emit("remote/logs");

      this.isReceivingLogs = true;
    },
    handleStartRemote() {
      switch (this.remoteState) {
        case REMOTESTATES.REMOTE_START:
          this.remoteRequest();
          break;
        case REMOTESTATES.REMOTE_WAITING:
          this.cancelRemote();
          this.remoteState = REMOTESTATES.REMOTE_START;
          break;
        case REMOTESTATES.REMOTE_ACCEPTED:
          this.state = SCREENSTATES.REMOTE_HOME;
          this.remoteState = REMOTESTATES.REMOTE_ACCEPTED;
          break;
        case REMOTESTATES.REMOTE_CANCELLED:
          this.remoteRequest();
          break;
      }
    },
    login() {
      const scope = this;
      getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          scope.$socket.emit(
            "login",
            `{"room":"remote/${scope.$route.params.id}","token":"${idToken}"}`,
            (error) => {
              if (error) {
                console.log(error);
              } else {
                scope.isConnected = true;
              }
            }
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendMessage(msg) {
      this.$socket.emit("sendMessage", msg);
    },
    remoteRequest() {
      this.remoteState = REMOTESTATES.REMOTE_WAITING;
      this.$socket.emit("remoteRequest");
    },
    cancelRemote() {
      this.$socket.emit("remoteRequest/cancel");
    },
    requestImage() {
      this.$socket.emit("remote/requestImage");
    },
    forcePreset(files) {
      this.handleStartPreset(files, true);
    },
    handleZero() {
      this.$socket.emit("remote/zero");
    },
    handleStartPreset(files, force = false) {
      const scope = this;

      const file = files;
      this.presetState = PRESETSTATE.PRESET_SENDFILE;

      if (file) {
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function (evt) {
          var line = this.result;

          let fileName = file.name.split(".")[0];
          scope.$socket.emit(
            "remote/preset",
            force ? `::FORCE_START::${fileName}` : `::START::${fileName}`
          );

          scope.$socket.emit("remote/preset", line);

          scope.$socket.emit("remote/preset", "::END::");
        };
        reader.onerror = function (evt) {
          //document.getElementById("fileContents").innerHTML =
          //  "error reading file";
        };
      }
    },
    handleStartBootload(files) {
      const scope = this;

      const file = files;
      this.bootloadState = BOOTSTATE.BOOT_SENDFILE;

      if (file) {
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function (evt) {
          var lines = this.result.split("\n");
          scope.$socket.emit("remote/bootload", "::START::");
          for (var line = 0; line < lines.length; line++) {
            scope.$socket.emit("remote/bootload", lines[line]);
          }
          scope.$socket.emit("remote/bootload", "::END::");
        };
        reader.onerror = function (evt) {
          //document.getElementById("fileContents").innerHTML =
          //  "error reading file";
        };
      }
    },
    resetPresets() {
      this.presetState = PRESETSTATE.PRESET_START;
    },
    resetBootload() {
      this.bootloadState = BOOTSTATE.BOOT_START;
      (this.bootloadProgress = "0"), (this.bootloadResp = "");
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
      selectedUser: (state) => state.selectedUser,
    }),
    isUserConnected() {
      const foundIdx = this.users.findIndex(
        (user) => user.name == this.$route.params.email
      );

      if (foundIdx != -1) {
        const user = this.users[foundIdx];
        return this.selectedUser.email + " connecté au serveur";
      }
      return this.selectedUser.email + " déconnecté du serveur";
    },
    powerModeText() {
      const powerMode = this.powerMode?.text;

      switch (powerMode) {
        case "A":
          return "Automatic";
        case "B":
          return "Manual";
        case "C":
          return "Reduced power mode, go to either end or start of sequence";
        case "D":
          return "Reduced power mode, go to start of sequence";
        case "E":
          return "Reduced power mode, go to end of sequence";
        default:
          return "Unknown power mode";
      }
    },
  },
};
</script>

<style scoped>
.remote-container {
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "main-container messages-container";
}

.main-container {
  grid-area: main-container;
  overflow-y: scroll;
  max-height: 100vh;
  overflow-x: hidden;
}

.messages-container {
  grid-area: messages-container;
  overflow-y: scroll;
  width: 100%;
  max-height: 100vh;
}

.connection-status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
}

.user-status-window {
  width: 400px !important;
  position: fixed;
  left: 20px;
  bottom: 20px;
}

.info-btn {
  position: absolute;
  left: 20px;
  bottom: 20px;
}
</style>
