<template>
  <v-row align="center" justify="center" style="height: 100%">
    <v-col cols="12" align="center">
      <h2>
        {{ getRemoteTitle }}
      </h2>
      <v-progress-linear
        v-if="
          remoteState == REMOTESTATES.REMOTE_WAITING ||
          remoteState == REMOTESTATES.REMOTE_ACCEPTED
        "
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
        style="width: 200px"
      ></v-progress-linear>
      <v-btn
        rounded
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('onClick')"
        :disabled="remoteState == REMOTESTATES.REMOTE_ACCEPTED"
      >
        {{ getRemoteButtonTitle }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { SCREENSTATES, REMOTESTATES } from "../../utils/enums";

export default {
  name: "RemoteStart",
  components: {},
  props: {
    remoteState: {
      type: Number,
      default: REMOTESTATES.REMOTE_WAITING
    }
  },
  data: () => {
    return {
      SCREENSTATES,
      REMOTESTATES
    };
  },
  methods: {},
  computed: {
    getRemoteTitle() {
      switch (this.remoteState) {
        case REMOTESTATES.REMOTE_START:
          return "Demande d'accès à distance";
        case REMOTESTATES.REMOTE_WAITING:
          return "En attente de l'approbation du client...";
        case REMOTESTATES.REMOTE_ACCEPTED:
          return "Accès à distance accepté";
        case REMOTESTATES.REMOTE_CANCELLED:
          return "Accès à distance annulé";
      }
    },
    getRemoteButtonTitle() {
      switch (this.remoteState) {
        case REMOTESTATES.REMOTE_START:
          return "Débuter";
        case REMOTESTATES.REMOTE_WAITING:
          return "Annuler";
        case REMOTESTATES.REMOTE_ACCEPTED:
          return "Commencer la session";
        case REMOTESTATES.REMOTE_CANCELLED:
          return "Réessayer";
      }
    }
  }
};
</script>
