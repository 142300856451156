<template>
  <v-row align="center" justify="center" style="height: 100%">
    <v-col cols="6" align="center">
      <h2>Preset</h2>
      <p>
        {{ presetName }}
      </p>
      <p>
        {{ presetReceived }}
      </p>
      <v-btn
        :loading="receivingPreset"
        rounded
        color="primary"
        style="margin: 24px"
        class="elevated-btn"
        @click="receivePreset"
      >
        Recevoir le preset
      </v-btn>
      <br />
      <h2>Mise à jour du Preset</h2>
      <p>
        {{ getPresetsTitle }}
      </p>
      <v-file-input
        v-if="presetState == PRESETSTATE.PRESET_START"
        v-model="files"
        color="deep-purple accent-4"
        counter
        label="Sélectionner un preset"
        placeholder="Sélectionner le fichier"
        prepend-icon="mdi-tune"
        accept=".adas"
        outlined
        :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
            v-if="index < 2"
            color="deep-purple accent-4"
            dark
            label
            small
          >
            {{ text }}
          </v-chip>

          <span
            v-else-if="index === 2"
            class="text-overline grey--text text--darken-3 mx-2"
          >
            +{{ files.length - 2 }} File(s)
          </span>
        </template>
      </v-file-input>
      <v-progress-linear
        v-if="
          presetState != PRESETSTATE.PRESET_START &&
          presetState != PRESETSTATE.PRESET_DONE
        "
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
        style="width: 200px"
      ></v-progress-linear>

      <v-btn
        rounded
        v-if="presetState == PRESETSTATE.PRESET_START"
        :disabled="files.length == 0"
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('onClick', files)"
      >
        Commencer la mise à jour
      </v-btn>
      <v-btn
        rounded
        v-if="presetState == PRESETSTATE.PRESET_DONE"
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('resetPage')"
      >
        Recommencer
      </v-btn>
      <v-btn
        rounded
        v-if="presetState == PRESETSTATE.PRESET_DONE"
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('forcePreset', files)"
      >
        Forcer la mise à jour
      </v-btn>
      <v-btn
        rounded
        color="primary"
        style="margin-top: 24px"
        class="elevated-btn"
        @click="$emit('onZeroClick')"
      >
        Retour à zéro
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import {
  SCREENSTATES,
  REMOTESTATES,
  BOOTSTATE,
  PRESETSTATE
} from "../../utils/enums";

export default {
  name: "RemoteBootload",
  components: {},
  props: {
    presetState: {
      type: Number,
      default: PRESETSTATE.PRESET_START
    },
    presetResp: {
      type: String,
      default: ""
    },
    presetProgress: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      SCREENSTATES,
      REMOTESTATES,
      BOOTSTATE,
      PRESETSTATE,
      files: [],
      presetReceived: "",
      presetName: "",
      receivingPreset: false,
      textFile: null
    };
  },
  methods: {
    receivePreset() {
      this.receivingPreset = true;
      const scope = this;
      this.$socket.emit("remote/preset/get");
      this.$socket.off("remote/preset/receive");
      this.$socket.on("remote/preset/receive", (preset) => {
        console.log("Packet received");
        scope.presetReceived = preset;
        scope.receivingPreset = false;
      });

      this.$socket.on("remote/preset/receive/name", (preset) => {
        console.log("Preset name received");
        scope.presetName = preset;
      });
    }
  },
  computed: {
    getPresetsTitle() {
      switch (this.presetState) {
        case PRESETSTATE.PRESET_START:
          return "";
        case PRESETSTATE.PRESET_SENDFILE:
          return "Téléchargement du preset en cours...";
        case PRESETSTATE.PRESET_WAITING:
          return "Preset reçu, Homing en cours...";
        case PRESETSTATE.PRESET_PROGRESS:
          if (this.presetProgress == "Homing in progress") {
            return `Homing en cours...`;
          } else if (this.presetProgress == "Homing success") {
            return `Succès du homing...`;
          } else {
            return `Chargement du preset en cours... Veuillez patienter...`;
          }
        case PRESETSTATE.PRESET_DONE:
          if (this.presetResp == "Success") {
            return "Mise à jour terminée.";
          } else {
            return "La mise à jour a échoué.";
          }
      }
    }
  }
};
</script>
