<template>
  <div>
    <div style="text-align: center; margin: auto; padding: 50px 0px">
      <img src="/adapt_logo_black.svg" />
    </div>
    <div style="text-align: center; margin: auto" v-if="showForm">
      <div style="width: 400px; margin: auto">
        <v-row style="margin-bottom: 20px">
          <v-btn icon color="primary" @click="$router.push('/login')">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-row>
        <v-form ref="form" v-model="valid">
          <h2 style="text-align: left">{{ $t("signup.approbation_form") }}</h2>

          <div style="position: relative; margin-top: 15px">
            <v-text-field
              v-model="email"
              :rules="[
                (v) => !!v || this.$t('signup.required'),
                (v) => /.+@.+/.test(v) || this.$t('signup.email_not_valid'),
              ]"
              :label="$t('signup.email')"
              required
              validate-on-blur
              autofocus
              style="margin: 0px 0px"
            ></v-text-field>

            <v-text-field
              v-model="firstname"
              :rules="requiredRules"
              :label="$t('signup.firstname')"
              required
              validate-on-blur
              style="margin: 0px 0px"
            ></v-text-field>

            <v-text-field
              v-model="lastname"
              :rules="requiredRules"
              :label="$t('signup.lastname')"
              required
              validate-on-blur
              style="margin: 0px 0px"
            ></v-text-field>

            <v-text-field
              v-model="company"
              :rules="requiredRules"
              :label="$t('signup.company')"
              required
              validate-on-blur
              style="margin: 0px 0px"
            ></v-text-field>

            <v-text-field
              v-model="phone"
              :rules="[
                (v) => !!v || $t('signup.required'),
                (v) =>
                  /(\(\d{3}\) \d{3}-\d{4})/.test(v) || $t('signup.phone_must'),
              ]"
              :label="$t('signup.phone')"
              required
              v-mask="'(###) ###-####'"
              validate-on-blur
              style="margin: 0px 0px"
            ></v-text-field>

            <!-- Country Selection -->
            <v-autocomplete
              v-model="selectedCountry"
              :items="countryNames"
              :rules="requiredRules"
              :label="$t('signup.country')"
              item-text="name"
              item-value="code2"
              return-object
              @change="onCountryChange"
              autocomplete="on"
            ></v-autocomplete>

            <v-textarea
              v-model="address"
              :rules="requiredRules"
              rows="2"
              :label="$t('signup.address')"
              required
              validate-on-blur
              style="margin: 0px 0px"
            ></v-textarea>

            <v-text-field
              v-model="city"
              :rules="requiredRules"
              :label="$t('signup.town')"
              required
              validate-on-blur
              style="margin: 0px 0px"
            ></v-text-field>

            <v-text-field
              v-model="postalcode"
              :rules="requiredRules"
              :label="$t('signup.postalcode')"
              required
              validate-on-blur
              style="margin: 0px 0px"
            ></v-text-field>

            <v-autocomplete
              v-model="selectedState"
              :items="stateNames"
              :rules="requiredRules"
              :label="$t('signup.province')"
              item-text="name"
              item-value="code"
              return-object
              :disabled="!selectedCountry"
              autocomplete="on"
            ></v-autocomplete>
          </div>
        </v-form>
        <v-row style="margin-top: 20px">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            :loading="isLoading"
            rounded
            color="primary"
            style="font-size: 12px"
            @click="signUp"
          >
            {{ $t("signup.ask_for_approbation") }}
          </v-btn>
        </v-row>
      </div>
    </div>

    <div
      v-else
      style="text-align: center; margin: auto; margin-top: calc(50% - 250px)"
    >
      <div>{{ text }}</div>

      <v-btn
        rounded
        color="primary"
        style="font-size: 12px; margin-top: 10px"
        @click="$router.push('/login')"
      >
        {{ $t("signup.return_to_login") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import countries from "./countries.json";

export default {
  name: "SignUp",
  components: {},
  data: () => {
    return {
      showForm: true,
      valid: true,
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      phone: "",
      address: "",
      city: "",
      postalcode: "",
      requiredRules: [(v) => !!v || "Required."],
      countries: countries,
      selectedCountry: null,
      selectedState: null,
      snackbar: false,
      text: "",
      mask: "#####", // Default to US ZIP code format
      isLoading: false,
    };
  },
  async mounted() {
    await this.$recaptchaLoaded();
  },
  computed: {
    countryNames() {
      return this.countries.map((country) => ({
        name: country.name,
        code2: country.code2,
        states: country.states,
      }));
    },
    stateNames() {
      return this.selectedCountry ? this.selectedCountry.states : [];
    },
  },
  methods: {
    onCountryChange() {
      this.selectedState = null; // Reset the state selection when the country changes
    },
    resetFields() {
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.company = "";
      this.phone = "";
      this.address = "";
      this.city = "";
      this.selectedCountry = "";
      this.selectedState = "";
      this.postalcode = "";
    },

    async signUp() {
      if (!this.valid) {
        return;
      }
      this.isLoading = true;
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("signup");

      try {
        const res = await this.$api.approbations.askApprobation({
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          company: this.company,
          phone: this.phone,
          address: this.address,
          city: this.city,
          country: this.selectedCountry.name,
          state: this.selectedState.name,
          postalcode: this.postalcode,
          recapchaToken: token,
        });
        console.log(res);
        if (res.status == 201 || res.status == 200) {
          this.text = this.$t("signup.thanks_for");
          this.snackbar = true;
          this.resetFields();
          //setTimeout(() => {
          //  this.$router.push("/login");
          //}, 5000);
        } else {
          this.text = res.data.message;
          this.snackbar = true;
          this.resetFields();
          //setTimeout(() => {
          //  this.$router.push("/login");
          //}, 5000);
        }
      } catch (e) {
        console.log(e);
        this.text = e.message;
        this.snackbar = true;
        this.resetFields();
        //setTimeout(() => {
        //  this.$router.push("/login");
        //}, 5000);
      }

      this.isLoading = false;
      this.showForm = false;
    },
  },
};
</script>

<style scoped>
.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
