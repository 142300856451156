<template>
  <div style="height: 100%; position: relative">
    <div
      style="
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      "
    >
      <span style="font-size: 100px">404</span>

      <router-link to="/">
        <v-btn style="display: block; margin: auto" color="primary">
          {{ $t("return_to_login") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
