<template>
  <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay ref="fragment">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          color="white"
          :disabled="link.serial < 11 || link.manufacturer < 15"
          style="float: right; margin-left: 20px"
        >
          <v-icon> mdi-barcode-scan </v-icon>
        </v-btn>
      </template>
      <v-card elevation="0" style="border-radius: 0px !important">
        <v-toolbar elevation="0">
          <LangButton></LangButton>
          <v-spacer></v-spacer>
          <v-btn
            @click="printFragment"
            fab
            color="white"
            style="float: right; margin-right: 30px"
            class="no-print"
          >
            <v-icon>mdi-printer-outline</v-icon>
          </v-btn>
          <v-btn icon @click.native="dialog = false" class="no-print">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container style="max-width: 800px">
          <div style="margin-left: calc(50% - 50px)">
            <img src="/logo.svg" style="width: 24px" />
            <img
              src="/adapt_logo_black.svg"
              style="width: 68px; margin-left: 12px; margin-bottom: 6px"
            />
          </div>
          <v-text-field
            v-if="link"
            v-model="link.serial"
            v-mask="'Link-!!!!!!'"
            solo
            readonly
          >
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-barcode</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("serial_number") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <v-text-field
            v-if="link"
            v-model="link.manufacturer"
            v-mask="'LK !! !!!! !!!!'"
            solo
            readonly
          >
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-barcode-scan</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("manufacturer_number") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>

          <v-text-field v-if="link" :value="getSBP(link.serial)" solo readonly>
            <template v-slot:prepend-inner>
              <v-row justify="center" align="center">
                <v-icon style="margin-left: 20px; margin-right: 10px"
                  >mdi-bluetooth</v-icon
                >
                <h5 style="margin-left: 10px; margin-right: 40px">
                  {{ $t("bluetooth_pass") }}
                </h5>
              </v-row>
            </template>
          </v-text-field>
          <v-row justify="center">
            <v-col cols="6" style="margin-top: 12px">
              <v-img width="300px" :src="image" />
            </v-col>
            <v-col align="left" cols="12">
              <div v-if="isFR" style="font-size: 13px">
                <p>
                  Ce LINK comprend un équipement de nouvelle génération. Cette
                  unité LINK dispose d'un module BLE (Bluetooth Low Energy)
                  intégré, supprimant ainsi le besoin d'un dongle Bluetooth.
                  Vous n'utiliserez <b>PAS</b> le dongle Bluetooth pour vous
                  connecter à cette unité. Vous devez mettre à jour
                  l'application LINK IT sur votre tablette à la version LINK IT
                  3.0.4 (ou plus récent).
                </p>
                <p>
                  Vous devrez ensuite coupler l'unité LINK avec la tablette.
                  Veuillez vous référer au tutoriel plus bas pour les
                  instructions.
                </p>
                <p>
                  Vous aurez besoin du numéro de série Bluetooth et du mot de
                  passe Bluetooth indiqués ci-dessus.
                </p>

                <p>
                  Si vous avez besoin d'assistance, veuillez contacter notre
                  équipe de support technique:
                </p>
                <p>tech@adaptsolutions.com - 866-641-0419</p>

                <div style="height: 20px"></div>

                <h3>Couplage du LINK avec votre tablette</h3>
                <div style="height: 10px"></div>

                <p>
                  1. Ouvrez la porte de la voiture pour réveiller le LINK. Il se
                  réveillera lorsqu'il détectera un mouvement.
                </p>
                <p>
                  2. Assurez-vous que votre tablette est déverrouillée et que le
                  Bluetooth est activé et accédez aux réglages Bluetooth de
                  votre appareil.
                </p>
                <p style="margin-left: 25px">
                  Se rendre dans Paramètres > Appareils connectés et dans
                  Préférences de connexion et ensuite Bluetooth > + Associer un
                  autre appareil
                </p>
                <p>
                  4. Appuyez sur {{ link.serial }} pour commencer l\'association
                  Bluetooth. S'il n'apparaît pas, assurez-vous que votre LINK
                  est alimenté et qu'il n'est pas en mode veille.
                </p>
                <p>
                  5. Le LINK demandera un mot de passe lors du processus de
                  couplage. Entrez votre mot de passe Bluetooth unique :
                  {{ getSBP(link.serial) }}. Cette clé est unique pour chaque
                  LINK. Ce mot de passe garantit que la communication entre
                  votre téléphone et le LINK est sécurisée.
                </p>
              </div>
              <div v-else style="font-size: 14px">
                <p>
                  This LINK includes new generation hardware. This LINK unit
                  features an integrated BLE (Bluetooth Low Energy) module,
                  eliminating the need for a Bluetooth dongle. You will
                  <b>NOT</b> use the Bluetooth dongle to connect to this unit.
                  You must update the LINK IT application on your tablet to LINK
                  IT v3.0.4 (or newer).
                </p>
                <p>
                  You will then pair the LINK unit with the tablet. Refer to the
                  short tutorial below for guidance.
                </p>
                <p>
                  You will need the unit's specific Bluetooth Serial number and
                  Bluetooth password printed above.
                </p>
                <p>
                  If you require assistance, please reach out to our technical
                  support team:
                </p>
                <span>tech@adaptsolutions.com - 866-641-0419</span>

                <div style="height: 20px"></div>
                <h3>Pairing LINK to your tablet</h3>

                <div style="height: 10px"></div>
                <p>
                  1. Open the car door to wake up the LINK. It will wake up when
                  it senses movement.
                </p>
                <p>
                  2. Make sure your tablet is unlocked, and Bluetooth is turned
                  on and go to the Bluetooth settings on your device.
                </p>
                <p style="margin-left: 25px">
                  Go to Settings > Connected devices and then Connection
                  preferences and then Bluetooth > + Pair new device
                </p>
                <p>
                  4. Tap {{ link.serial }} to start the pairing process. If it
                  doesn\'t show up, make sure your LINK is powered on and not in
                  sleep mode.
                </p>
                <p>
                  5. The LINK will ask for a password during the pairing
                  process. Enter your unique Bluetooth password when asked:
                  {{ getSBP(link.serial) }}. This key is unique to every LINK.
                  This password ensures that communication between your phone
                  and the LINK is secure.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { parseError, formatUser, getSBP } from "../utils";
import LangButton from "./LangButton.vue";
export default {
  name: "LinkGettingStarted",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: {},
    },
  },
  components: { LangButton },
  data: () => {
    return {
      image: "/assets/images/bluetoothSelection.png",
      logo: "/assets/adapt_logo_black.svg",
      uuid: "",
      pass: "",
    };
  },
  computed: {
    dialog: {
      // getter
      get() {
        return this.value;
      },
      // setter
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isFR() {
      return this.$i18n.locale == "fr";
    },
  },
  methods: {
    getSBP,
    printFragment() {
      window.print();
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
