function parseError(error) {
  let res = "";
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      res = error.response.data.message;
    } else {
      res = error.response.data;
    }
  } else if (error.message) {
    res = error.message;
  } else {
    res = error.toString();
  }
  if (typeof res === "object") {
    res = JSON.stringify(res, null, 2);
  }
  return res;
}

function formatUser(user) {
  if (user.role && user.role.find((r) => r == "admin")) {
    user.singleRole = "admin";
  } else if (user.role && user.role.find((r) => r == "tech")) {
    user.singleRole = "tech";
  } else if (user.role && user.role.find((r) => r == "installer")) {
    user.singleRole = "installer";
  } else if (user.role && user.role.find((r) => r == "prod"))
    user.singleRole = "prod";
  else {
    user.singleRole = "user";
  }
}

function getStringFromBytes(bytes, offset, length) {
  let workBytes =
    length - offset == bytes.length
      ? bytes
      : bytes.slice(offset, offset + length);
  return `0x${workBytes}`;
}

function getIntFromByteHexString(bytes, offset, length) {
  return Number(getStringFromBytes(bytes, offset, length));
}

function toShort(a) {
  return (a << 16) >> 16;
}

function getSBP(name) {
  if (name.length === 11 && name.startsWith("Link-")) {
    let hex = name.slice(5); // Extract the hex part
    let bdAddr2 = parseInt(hex.slice(0, 2), 16);
    let bdAddr1 = parseInt(hex.slice(2, 4), 16);
    let bdAddr0 = parseInt(hex.slice(4, 6), 16);

    let dwTemp = bdAddr0 + (bdAddr1 << 8) + (bdAddr2 << 16);
    dwTemp += 6410419;
    let passkey = dwTemp % 1000000;

    // Pad the passkey with leading zeros to ensure it's 6 digits long
    return passkey.toString().padStart(6, '0');
  }
  return "";
}

export {
  parseError,
  formatUser,
  getIntFromByteHexString,
  getStringFromBytes,
  toShort,
  getSBP,
};
