export default function ($axios) {
  return {
    async askApprobation(body) {
      const res = await $axios({
        method: "post",
        responseType: "json",
        url: "/api/approbation/ask",
        data: body,
      });

      if (res) {
        return res;
      } else {
        throw "Error asking approbations";
      }
    },

    async getApprobations() {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: "/api/approbations",
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting approbations";
      }
    },

    async getApprobation(id) {
      const res = await $axios({
        method: "get",
        responseType: "json",
        url: `/api/approbation/${id}`,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting approbations";
      }
    },

    async removeApprobation(id) {
      const res = await $axios({
        method: "delete",
        responseType: "json",
        url: `/api/approbation/${id}`,
      });

      if (res) {
        return res.data;
      } else {
        throw "Error getting approbations";
      }
    },
  };
}
