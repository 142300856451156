<template>
  <div>
    <v-hover v-slot="{ hover }" style="cursor: pointer">
      <v-card
        :elevation="hover ? 5 : 2"
        class="linkbox-card"
        @click="$router.push('/approbation/' + approbation.id)"
      >
        <div class="linkbox-container">
          <div class="card-icon">
            <v-icon class="linkbox-icon"
              >mdi-card-account-details-outline</v-icon
            >
          </div>
          <div class="card-title">
            {{ approbation.email }}
          </div>
          <div class="card-subtitle">
            {{ approbation.phone }}
          </div>
          <div class="card-threeline">
            {{ approbation.ip + " - " + approbation.createdAt }}
          </div>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "ApprobationBox",
  components: {},
  props: {
    approbation: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.linkbox-icon {
  margin: 0;
  padding-left: 13px;
  padding-top: 13px;
}
.linkbox-info {
  display: inline-block;
}
.linkbox-card {
  width: 100%;
  height: 70px;
  white-space: nowrap;
}
.linkbox-container {
  display: grid;
  max-height: 70px;
  grid-template-columns: 0.5fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "card-icon card-title card-title"
    "card-icon card-subtitle card-subtitle"
    "card-icon card-threeline card-threeline";
}
.card-icon {
  grid-area: card-icon;
}
.card-title {
  grid-area: card-title;
  font-weight: 700;
  padding-top: 5px;
}
.card-subtitle {
  grid-area: card-subtitle;
  font-size: 11px;
}
.card-threeline {
  grid-area: card-threeline;
  font-size: 10px;
}
.card-bottom {
  grid-area: card-bottom;
  font-size: 11px;
  vertical-align: middle;
  padding-top: 5px;
}
</style>
