<template>
  <div>
    <div style="text-align: center; margin: auto; padding: 130px 0px">
      <img src="/adapt_logo_black.svg" />
    </div>
    <div style="text-align: center; margin: auto">
      <div style="width: 300px; margin: auto">
        <v-form ref="form" v-model="valid">
          <h2 style="text-align: left">{{ $t("login") }}</h2>

          <div style="position: relative">
            <div v-show="!showPassword" class="v-fade">
              <div style="text-align: left; color: #7c7c7c">{{ $t("username") }}</div>
              <v-text-field
                v-model="username"
                :rules="emailRules"
                required
                solo
                validate-on-blur
                autofocus
                style="margin: 0px 0px"
              ></v-text-field>

              <p v-if="rejectText != ''">{{ rejectText }}</p>

              <v-btn
                :disabled="!valid"
                rounded
                block
                color="primary"
                style="font-size: 12px"
                @click="next"
              >
                {{ $t("next") }}
              </v-btn>
              <div style="height: 60px"></div>
              <p>{{ $t("dont_have_an_account_yet") }}</p>

              <v-btn
                :disabled="!password"
                rounded
                block
                color="primary"
                style="font-size: 12px"
                @click="signUp"
              >
                {{ $t("register") }}
              </v-btn>
            </div>

            <div v-show="showPassword" class="v-fade">

              <div style="text-align: left; color: #7c7c7c">{{ username }}</div>

              <div style="text-align: left; margin-top: 20px; color: #7c7c7c">{{ $t('password') }}</div>


              <v-text-field
                v-model="password"
                solo
                type="password"
                style="margin: 0px 0px"
              ></v-text-field>

              <v-row>
                <v-col>
                  <v-btn plain style="font-size: 12px" @click="back">
                    {{ $t("back") }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    :disabled="!password"
                    rounded
                    block
                    color="primary"
                    style="font-size: 12px"
                    @click="signIn"
                    :loading="connecting"
                  >
                    {{ $t("login") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div style="height: 60px"></div>
              <v-dialog v-model="resetPasswordDialog" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!password"
                    rounded
                    block
                    color="primary"
                    style="font-size: 12px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("reset_password") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t("reset_password") }}
                  </v-card-title>
                  <v-card-text> {{ $t("confirm_reset") }}</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="resetPasswordDialog = false"
                      >{{ $t("no") }}
                    </v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      @click="resetPassword"
                      :loading="resetting"
                    >
                      {{ $t("yes") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </v-form>
      </div>
    </div>

    <v-snackbar v-model="showError" color="primary">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showError = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="showResetPasswordSent" color="primary">
      {{ $t("email_sent_to") }}{{ username }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showResetPasswordSent = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <LangButton style="position: fixed; right: 10px; top: 10px"></LangButton>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import jwt_decode from "jwt-decode";

import LangButton from "../components/LangButton.vue";
export default {
  name: "Login",
  components: { LangButton },
  data: () => {
    return {
      valid: false,
      resetting: false,
      username: "",
      password: "",
      showPassword: false,
      emailRules: [],
      connecting: false,
      showError: false,
      error: "",
      showResetPasswordSent: false,
      rejectText: "",
      resetPasswordDialog: false,
    };
  },
  mounted() {
    this.emailRules = [
      (v) => !!v || this.$t("username_required"),
      (v) => /.+@.+/.test(v) || this.$t("username_valid"),
    ];
  },
  methods: {
    signUp() {
      this.$router.push("/signup");
    },
    back() {
      this.showPassword = false;
      this.$refs.form.validate();
    },
    next() {
      this.showPassword = true;
    },
    async resetPassword() {
      try {
        this.resetting = true;
        await this.$api.users.resetPassword(this.username);
        this.showResetPasswordSent = true;
      } catch (err) {
        console.log(err);
        this.error = err;
        this.showError = true;
      }

      this.resetting = false;
      this.resetPasswordDialog = false;
    },
    async signIn() {
      if (this.connecting) {
        return;
      }

      try {
        this.connecting = true;

        const auth = getAuth();

        const userCredentials = await signInWithEmailAndPassword(
          auth,
          this.username,
          this.password
        );

        let decoded = jwt_decode(userCredentials.user.accessToken);
        let roles = [];
        console.log("DECODED", decoded);
        if (decoded.role) roles = decoded.role;

        if (
          roles.includes("admin") ||
          roles.includes("tech") ||
          roles.includes("installer")
        ) {
          this.$router.push("/");
        } else {
          this.username = "";
          this.password = "";
          this.showPassword = false;
          this.rejectText = this.$t("unauthorized");
        }
      } catch (err) {
        //console.log(err);

        this.username = "";
        this.password = "";
        this.showPassword = false;

        this.error = this.$t("wrong");
        this.showError = true;
      } finally {
        this.connecting = false;
      }
    },
  },
};
</script>

<style scoped>
.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
