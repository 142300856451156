<template>
  <div>
    <v-list three-line v-if="logs && logs.logs">
      <v-card>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Numéro de série</v-list-item-title>
            <v-list-item-subtitle>{{ logs.serialNumber }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Version logicielle</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.linkSoftwareVersion
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Date dans la carte</v-list-item-title>
            <v-list-item-subtitle>{{ logs.actualDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Nombre de cycles</v-list-item-title>
            <v-list-item-subtitle>{{ logs.cycleCount }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Jour(s) avant maintenance</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.daysBeforeMaintenance
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Compteur de maintenance reset</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.maintenanceResetCount
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Compteur de eeprom Writes</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.eepromWrites ? logs.eepromWrites : 0
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-subheader>Historique</v-subheader>
      <template v-for="(item, index) in logs.logs">
        <v-card elevation="2" style="margin: 5px" :key="'log' + index">
          <v-list-item>
            <v-list-item-icon>
              <v-icon> mdi-math-log </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-html="
                  item.logId + '::' + item.eventName + '::' + item.logPayload
                "
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.eventDate"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "RemoteLogs",
  components: {},
  props: {
    logs: {
      type: Object,
      default: {},
    },
  },
  data: () => {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
