<template>
  <v-row style="height: 100%" align="center" justify="center">
    <v-col cols="6" align="center" justify="center">
      <h2>Commandes pendant</h2>
      <v-row style="width: 200px" align="center" justify="center">
        <template v-for="(command, index) in btnCommands">
          <v-col
            :key="command + index"
            cols="6"
            style="width: 100px; height: 100px; display: inline-block"
          >
            <v-btn
              fab
              color="primary"
              style="margin: 25px; display: inline-block"
              @mousedown="sendCommand(command.command)"
              @mouseup="sendCommand('stop')"
              @mouseleave="interval ? sendCommand('stop') : null"
            >
              <v-progress-circular
                v-if="command.isPressed"
                indeterminate
                color="white"
                :size="24"
              ></v-progress-circular>
              <img
                v-else-if="command.command != 'stop'"
                width="60px"
                height="60px"
                :src="`../../assets/icons/BoutonSvg.svg`"
              />
              <v-icon v-else color="red">mdi-stop</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="(index + 1) % 2 == 0 && index !== 0"
            cols="12"
            :key="'buttonsinfo' + index"
            align="center"
            justify="center"
          >
            <img
              width="140px"
              style="margin-left: 25px"
              :src="`../../assets/icons/${getUnderIcon(index)}.svg`"
            />
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { COMMANDS } from "@/utils/enums";
/*
    BACKWARD: "backward",
    FORWARD: "forward",
    DOWNWARD: "downward",
    UPWARD: "upward",
    INWARD: "inward",
    OUTWARD: "outward",
    STOP: "stop"*/
export default {
  name: "RemoteCommands",
  components: {},
  props: {},
  data: () => {
    return {
      COMMANDS,
      btnCommands: null,
      interval: null,
      diagnostic: "",
      receivingDiag: false,
      receivingInterval: null,
    };
  },
  mounted() {
    this.btnCommands = [];
    for (const [key, value] of Object.entries(COMMANDS)) {
      this.btnCommands.push({
        command: value,
        isPressed: false,
      });
    }
  },
  methods: {
    getUnderIcon(idx) {
      console.log(idx);
      switch (idx) {
        case 1:
          return "backward_forward";
        case 3:
          return "downward_upward";
        case 5:
          return "inward_outward";
        default:
          return "";
      }
    },
    sendCommand(command) {
      if (command === COMMANDS.STOP) {
        this.btnCommands.forEach((c) => {
          c.isPressed = false;
        });

        if (this.interval) {
          clearInterval(this.interval);
        }

        this.$socket.emit("remote/command", COMMANDS.STOP);

        return;
      } else {
        this.btnCommands.forEach((c) => {
          if (c.command === command) {
            c.isPressed = true;
          } else {
            c.isPressed = false;
          }
        });
      }

      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
        this.interval = setInterval(() => {
          console.log("SEND COMMAND", Date.now());
          this.$socket.volatile.emit("remote/command", command);
        }, 150);
      } else {
        this.interval = setInterval(() => {
          console.log("SEND COMMAND", Date.now());
          this.$socket.volatile.emit("remote/command", command);
        }, 150);
      }
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  computed: {},
};
</script>
